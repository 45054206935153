import React from 'react';

const locationArray = [
      "AL - Alabama",
      "AK - Alaska",
      "AZ - Arizona",
      "AR - Arkansas",
      "CA - California",
      "CO - Colorado",
      "CT - Conneticut",
      "DE - Delaware",
      "DC - District of Columbia",
      "FL - Florida",
      "GA - Georgia",
      "HI - Hawaii",
      "ID - Idaho",
      "IL - Illinois",
      "IN - Indiana",
      "IA - Iowa",
      "KS - Kansas",
      "KY - Kentucky",
      "LA - Louisiana",
      "ME - Maine",
      "MD - Maryland",
      "MA - Massachusetts",
      "MI - Michigan",
      "MN - Minnesota",
      "MS - Mississippi",
      "MO - Missouri",
      "MT - Montana",
      "NE - Nebraska",
      "NV - Nevada",
      "NH - New Hampshire",
      "NJ - New Jersey",
      "NM - New Mexico",
      "NY - New York",
      "NC - North Carolina",
      "ND - North Dakota",
      "OH - Ohio",
      "OK - Oklahoma",
      "OR - Oregon",
      "PA - Pennslyvania",
      "PR - Puerto Rico",
      "RI - Rhode Island",
      "SC - South Carolina",
      "SD - South Dakota",
      "TN - Tennessee",
      "TX - Texas",
      "UT - Utah",
      "VT - Vermont",
      "VA - Virginia",
      "WA - Washington",
      "WV - West Virginia",
      "WI - Wisconsin",
      "WY - Wyoming",
      "Canada - AB",
      "Canada - BC",
      "Canada - MB",
      "Canada - NB",
      "Canada - NL",
      "Canada - NT",
      "Canada - NS",
      "Canada - NU",
      "Canada - ON",
      "Canada - PE",
      "Canada - QC",
      "Canada - SK",
      "Canada - YT",
      "International - Other",
    ];

const StateSelectField = ({state, onChange}) => {
  
  const setUpOptions = () => {
    return locationArray.map((state) => (
      <option value={state} key={state}>
        {state}
      </option>
    ));
  };

  const onStateChange = (event) => {
    return onChange(event.target.value);
  };

  return (
    <select
      className={'state-dropdown third-field'}
      name={'state'}
      onChange={onStateChange}
      value={state}
    >
      {setUpOptions()}
    </select>
  );
};

export default StateSelectField;

