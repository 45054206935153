import React, { useState } from 'react';
import AttendeeCard from './attendee_card.js';

const AttendeeList = ({ profiles }) => {
  const [visibleCount, setVisibleCount] = useState(3);

  const sortedProfiles = profiles
    .slice()
    .sort((a, b) => new Date(b[2]) - new Date(a[2]));

  const showMore = () => setVisibleCount(prevCount => prevCount + 3);

  return (
    <div>
      {sortedProfiles.slice(0, visibleCount).map((profile, index) => (
        <AttendeeCard key={index} profile={profile} />
      ))}
      {visibleCount < sortedProfiles.length && (
        <button onClick={showMore}>Show More</button>
      )}
      <button onClick={() => {/* logic to go to next step */}}>
        Register New Attendee
      </button>
    </div>
  );
};

export default AttendeeList;
