import { Controller } from "@hotwired/stimulus";
import 'selectize';

export default class extends Controller {
  static targets = ["milestone", "fieldsContainer", "validationMessage", "destroyField", "defaultCheckbox"];

  addFields(event) {
    event.preventDefault();
    const time = new Date().getTime();
    const addButton = event.currentTarget;
    const regexp = new RegExp(addButton.dataset.id, 'g');
    const template = addButton.dataset.fields.replace(regexp, time);
    addButton.insertAdjacentHTML('beforebegin', template);
  }

  removeFields(event) {
    event.preventDefault();
    const fieldset = event.currentTarget.closest("fieldset.event-milestone");
    const markToDelete = fieldset.querySelector("input.destroy-field");
    const fields = fieldset.querySelectorAll("input");
    fields.forEach(field => field.removeAttribute('required'));
    if (markToDelete) {
      markToDelete.value = "1";
    } else {
      console.error("No input.destroy-field found within the fieldset.");
    }
    fieldset.style.display = "none";
  }
}
