import React from "react";

const CartRegistration = ({ first_name, last_name, price_point_data }) => {
  const formatCurrency = (number) => {
    return number.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <div className="cart-registration">
      <p>{first_name}</p>
      <p>{last_name}</p>
      <p>{price_point_data.title}</p>
      <p>{formatCurrency(price_point_data.price)}</p>
      <p>COMPLETE</p>
    </div>
  );
};

export default CartRegistration;
