import React, { useState, useEffect } from 'react';
import CountryCodeSelect from '../form_fields/country_code_select.js';
import Cleave from 'cleave.js/react';

const EmergencyContactNumberInput = ({ formData, formDataPhoneNumber, countryCodeDropdownChanged, handlePhoneChange, checkInput }) => {
  const [countryCode, setCountryCode] = useState('');
  const [emergencyContactNumber, setEmergencyContactNumber] = useState('');
  const errorMessage = "Emergency contact phone number must include at least 10 digits.";

  useEffect(() => {
    if (formDataPhoneNumber && formDataPhoneNumber.trim() !== '') {
      const [code, number] = formDataPhoneNumber.split(' ');
      if (code) {
        setCountryCode(code);
      }
      setEmergencyContactNumber(number);
    }
  }, [formDataPhoneNumber]);

  const hasError = emergencyContactNumber.replace(/[^0-9]/g, '').length < 10 || 
                    (countryCode.replace(/[^0-9]/g, '') + emergencyContactNumber.replace(/[^0-9]/g, '')).length > 20;

  const modifiedHandlePhoneChange = (event) => {
    const updatedNumber = event.target.rawValue;
    console.log('phone changed!')
    if ((countryCode.replace(/[^0-9]/g, '') + updatedNumber).length <= 20) {
      setEmergencyContactNumber(updatedNumber);
    }
    if (handlePhoneChange) {
      handlePhoneChange(event); // Original handler
    }
  };

  const onCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
    console.log('code dropdown changed!')
    if (countryCodeDropdownChanged) {
      countryCodeDropdownChanged(event); // Original handler
    }
  };

  return (
    <div className="half">
      {hasError && (
        <span className="error-span" role="alert" aria-live="assertive">
          {errorMessage}
        </span>
      )}
      <label htmlFor="countryCodeDropdown" className="visually-hidden">
        Country Code
      </label>
      <CountryCodeSelect
        id="countryCodeDropdown"
        value={countryCode}
        onChange={onCountryCodeChange}
      />

      <label htmlFor="emergency_contact_number" className="visually-hidden">
        Emergency Contact Number
      </label>
      <Cleave
        id="phone-mask-ecn"
        className="ecn phone-us field two-thirds-field"
        type="tel"
        name="emergency_contact_number"
        value={emergencyContactNumber}
        placeholder="Emergency Contact #"
        options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
        onChange={modifiedHandlePhoneChange}
        onBlur={checkInput}
        aria-invalid={hasError}
        aria-describedby={hasError ? "phoneError" : null}
      />
    </div>
  );
};

export default EmergencyContactNumberInput;
