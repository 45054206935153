import React, { useState } from 'react';

const PricePointVisualSelection = ({ price_points, onChange }) => {
  const [pricePoint, setPricePoint] = useState('');

  const onPricePointChange = (event) => {
    setPricePoint(event.target.value);
    return onChange(event.target.value);
  };

  const setAvailablePricePoints = (pricePointArray) => {};

  return (
    <select
      className="price_point"
      id="price_point_select"
      name="price_point"
      value={pricePoint}
      onChange={onPricePointChange}
    >
      {price_points.map((price_point) => (
        <option key={price_point.id} value={price_point.id}>
          {price_point.title} - ${(price_point.price_cents / 100).toString()}
        </option>
      ))}
    </select>
  );
};

export default PricePointVisualSelection;
