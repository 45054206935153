import React, { useState } from "react";

const RoommatePreferenceField = () => {
  const [roommatePreference, setRoommatePreference] = useState("");

  const handleChange = (event) => {
    setRoommatePreference(event.target.value);
  };

  return (
    <div className="half">
      <label>
        Roommate Preferences:
        <span className="error-span" />
        <textarea
          rows="5"
          placeholder="Please list any roommate preferences..."
          name="roommate_preference"
          value={roommatePreference}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default RoommatePreferenceField;
