import React, { useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CovidInfoForm = ({ saveValues, nextStep, buttonStyles }) => {
  const [state, setState] = useState({
    vaccinated: false,
    vaccine_type: "Pfizer",
    vaccine_dose_one_date: new Date(),
    vaccine_dose_two_date: new Date(),
    positive_test_date: new Date(),
    covid_positive: false,
    covid_cleared: false,
  });

  const valid = () => {
    if(state.vaccinated && state.vaccine_type){
      return true;
    }
    return false;
  };

  const handleToggleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.checked }));
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const handleDateChange = (date, name) => {
    setState((prevState) => ({ ...prevState, [name]: date }));
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    saveValues(state);
    nextStep();
  };

  return (
    <form className={"new-step-form"} autoComplete={"off"}>
      <div className="row">
        <label htmlFor="vaccinated">Have you received the Covid-19 vaccine?</label>
        <Toggle
          id="vaccinated"
          name="vaccinated"
          value={state.vaccinated.toString()}
          onChange={handleToggleChange}
        />
      </div>
      {state.vaccinated && (
        <div className="parent-row row">
          <label htmlFor="vaccinated">Which vaccine did you receive?</label>
          <select
            name="vaccine_type"
            value={state.vaccine_type}
            onChange={handleChange}
          >
            <option value="Pfizer">Pfizer</option>
            <option value="Moderna">Moderna</option>
            <option value="Johnson & Johnson">Johnson & Johnson</option>
            <option value="Other">Other</option>
            <option value="None">None</option>
          </select>
          <div className="row">
            <p>Vaccine #1 date</p>
            <DatePicker
              name={"vaccine_dose_one_date"}
              id="vaccine_dose_one_date"
              title={"vaccine_dose_one_date"}
              selected={state.vaccine_dose_one_date}
              onChange={(date, e) =>
                handleDateChange(date, "vaccine_dose_one_date", e)
              }
            />
          </div>
          <div className="row">
            <p>Vaccine #2 date</p>
            <DatePicker
              name={"vaccine_dose_two_date"}
              id="vaccine_dose_two_date"
              title={"vaccine_dose_two_date"}
              selected={state.vaccine_dose_two_date}
              onChange={(date, e) =>
                handleDateChange(date, "vaccine_dose_two_date", e)
              }
            />
          </div>
        </div>
      )}
      <div className="row">
        <p htmlFor="vaccinated">Have you tested positive for Covid-19?</p>
        <Toggle
          id="covid_positive"
          name="covid_positive"
          value={state.covid_positive.toString()}
          onChange={handleToggleChange}
        />
      </div>
      {state.covid_positive && (
        <div className="parent-row row">
          <div className="row">
            <p htmlFor="covid_cleared">
              If yes, have you since been fully cleared by your doctor to fully
              participate in individual activity?
            </p>
            <Toggle
              id="covid_cleared"
              name="covid_cleared"
              value={state.covid_cleared.toString()}
              onChange={handleToggleChange}
            />
          </div>
          <div className="row">
            <p>When did you test positive?</p>
            <DatePicker
              name={"positive_test_date"}
              id="positive_test_date"
              title={"positive_test_date"}
              selected={state.positive_test_date}
              onChange={(date, e) =>
                handleDateChange(date, "positive_test_date", e)
              }
            />
          </div>
        </div>
      )}
      <button
        type={"submit"}
        className={"button button-primary create"}
        style={buttonStyles}
        disabled={!valid()}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
};

export default CovidInfoForm;
