import React from "react";

const SatScoreField = ({ name, label, value, onChange }) => {
  const generateSATScoreOptions = () => {
    const options = [];
    for (let score = 400; score <= 1600; score += 10) {
      options.push(
        <option key={score} value={score}>
          {score}
        </option>
      );
    }
    return options;
  };

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        {generateSATScoreOptions()}
      </select>
    </div>
  );
};

export default SatScoreField;
