import React from 'react';
import StateDropdown from "./state_dropdown.js";
import CountryCodeDropdown from "./country_code_dropdown.js";

// Props include handlers and current formData values for the address-related fields
const AddressComponent = ({ formData, handleChange, handleBlur, stateDropdownChanged }) => {
  return (
    <>
      <div className="camper-info-row address">
        <label>Primary Address: *</label>
        <span className="error-span"></span>
        <input
          type="text"
          className="address full-field"
          placeholder="Street Address"
          data-error-message="Please enter a valid address"
          data-min-length="4"
          name="address"
          minLength={4}
          value={formData.address}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="camper-info-row address">
        <span className="error-span"></span>
        <input
          type="text"
          className="city full-field"
          placeholder="City"
          data-error-message="City must be longer than the specified number of characters."
          data-min-length="4"
          name="city"
          minLength={4}
          value={formData.city}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className="camper-info-row">
        <div className="error-span"></div>
        <StateDropdown value={formData.state} onChange={stateDropdownChanged} />
        <input
          type="text"
          className="zip field third-field"
          placeholder="Zip Code (i.e. 20008)"
          data-error-message="Please enter a valid zip code."
          data-min-length="2"
          name="zip"
          minLength={2}
          maxLength={10}
          value={formData.zip}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
    </>
  );
};

export default AddressComponent;

