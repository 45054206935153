import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="user-menu"
export default class extends Controller {
  static targets = [ "userMenuList", "userMenuLink" ]

  connect() {
    window.addEventListener('resize', this.checkScreenWidth);
  }

  disconnect() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth(){
    const userMenuList = document.querySelector("#user-menu-list")
    if (window.innerWidth > 1100) {
      userMenuList.style.display = 'block'
    } else {
      userMenuList.style.display = 'none'
    }
  }

  toggleUserMenu() {
    this.userMenuListTarget.style.display = (this.userMenuListTarget.style.display == '' || this.userMenuListTarget.style.display == 'none') ? 'block' : 'none';
    
     this.userMenuLinkTargets.forEach((menuLink) => {
        if (menuLink.style.display == '' || menuLink.style.display == 'none') {
          menuLink.style.display = 'block'
        }
     })
  }
}


