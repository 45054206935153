// Import all components from the "components" directory and use them with ReactRailsUJS

// Use ES6 import syntax for consistency
import { useContext } from 'react_ujs';

// Require all components within the "components" directory
const componentRequireContext = require.context('components', true);

// Use the imported 'useContext' function to set the context for ReactRailsUJS
useContext(componentRequireContext);
