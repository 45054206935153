import React from 'react';
import JmuCamperInfoForm from './jmu_camper_info_form';
import MedicalForm from './medical_form';
import LiabilityWaiverForm from './liability_waiver_form';
import PaymentForm from './payment_form';
import ExciteInfoForm from './excite_info_form';
import ExciteMedicalForm from './excite_medical_form';
import SanDiegoStateMedicalForm from './san_diego_state_medical_form';
import TexasStateSportForm from './texas_state_sport_form';
import SportsPerformanceForm from './sports_performance_form';
import CovidInfoForm from './covid_info_form';
import CustomInfoForm from './custom_info_form';
import UtepCamperInfoForm from './utep_camper_info_form';
import UtepMedicalForm from './utep_medical_form';
import UtepTransportAuthorizationForm from './utep_transport_authorization_form';
import UtepSportForm from './utep_sport_form';
import UtepLiabilityWaiverForm from './utep_liability_waiver_form';
import WaitlistModal from './waitlist_modal';
import CamperInfoForm from './camper_info_form';

const traditionalRegistration = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            buttonStyles={props.button_styles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className="event-reg camper-info-form">
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className="event-reg medical-form">
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className="event-reg soccer-form">
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg payment-form">
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={document
                .querySelector("meta[name=csrf-token]")
                .getAttribute("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const jmuRegistrationFlow = () => {
    switch (step) {
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <JmuCamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camper_identifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };

  const exciteRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            buttonStyles={props.button_styles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <ExciteInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg liability-form"}>
            <ExciteMedicalForm
              stepLabel={step}
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              saveValues={saveValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 4:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };

  const sanDiegoStateRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            button_style={props.buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <SanDiegoStateMedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className={"event-reg liability-form"}>
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };
  const sportsPerformanceRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            button_style={props.buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            <SportsPerformanceForm
              buttonStyles={props.button_styles}
              event_type={props.event_type}
              custom_registration_type={props.custom_registration_type}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              sport={props.sport}
              price_points={props.public_price_points}
              full_position_price_points={createFullPricePointPositionArray()}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };
  const texasStateRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            button_style={props.buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            <TexasStateSportForm
              buttonStyles={props.button_styles}
              event_type={props.event_type}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={props.campers}
              sport={props.sport}
              price_points={props.public_price_points}
              full_position_price_points={createFullPricePointPositionArray()}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };
  const wakeForestRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            button_style={props.buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <CamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <MedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className={"event-reg soccer-form"}>
            {setDetailForm(
              props.event_type,
              props.sport,
              props.collect_tee_shirt_size
            )}
          </div>
        );
      case 4:
        return (
          <div className="event-reg">
            <CovidInfoForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 6:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };

  const utepRegistrationFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            button_style={props.buttonStyles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <UtepCamperInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              campers={campers}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 2:
        return (
          <div className={"event-reg medical-form"}>
            <UtepMedicalForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 3:
        return (
          <div className="event-reg">
            <UtepTransportAuthorizationForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg liability-form">
            <UtepSportForm
              buttonStyles={props.button_styles}
              gender={props.gender}
              sport={props.sport}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 5:
        return (
          <div className="event-reg liability-form">
            <UtepLiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              custom_data={campers[camperIdentifier]["custom_data"]}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 6:
        return (
          <div className={"event-reg payment-form"}>
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={$("meta[name=csrf-token]").attr("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
    }
  };

  const customizedDataCollectionFlow = () => {
    switch (step) {
      case 0:
        return (
          <WaitlistModal
            waitlist_price_point_ids={props.waitlist_price_point_ids}
            buttonStyles={props.button_styles}
            camp_full={campFull()}
            show_modal={setFullPricePointFlag()}
            full_price_points={createFullPricePointArray()}
            open_price_points={createOpenPricePointArray()}
            nextStep={nextStep}
          />
        );
      case 1:
        return (
          <div className={"event-reg camper-info-form"}>
            <ExistingAttendeeForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={initializeValues}
              nextStep={nextStep}
              existing_profiles={props.existing_profiles}
              onExistingCamperSelect={initializeExistingAttendeeValues}
              data_points={props.datapoints}
              event_type={props.event_type}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>)
      case 2:
        return (
          <div className="event-reg camper-info-form">
            <CustomInfoForm
              buttonStyles={props.button_styles}
              collect_guardian_info={props.collect_guardian_info}
              collect_tee_shirt_size={props.collect_tee_shirt_size}
              collect_initials={props.collect_initials}
              category={props.category}
              saveValues={saveValues}
              nextStep={nextStep}
              campers={campers}
              existingCamperId={existingCamperId}
              existingAttendeeData={campers[existingCamperId]}
              sport={props.sport}
              event_type={props.event_type}
              data_points={props.datapoints}
              price_points={props.public_price_points}
              waitlist_price_point_ids={props.waitlist_price_point_ids}
            />
          </div>
        );
      case 3:
        return (
          <div className="event-reg liability-form">
            <LiabilityWaiverForm
              buttonStyles={props.button_styles}
              saveValues={saveValues}
              previousStep={previousStep}
              nextStep={nextStep}
              camper_identifier={camperIdentifier}
              campers={campers}
              waiver={props.waiver}
              signature_name={props.signature_name}
              collect_initials={props.collect_initials}
            />
          </div>
        );
      case 4:
        return (
          <div className="event-reg payment-form">
            <PaymentForm
              buttonStyles={props.button_styles}
              fee_breakout={props.fee_breakout}
              fee_percentage={props.fee_percentage}
              camp_id={props.camp_id}
              event_title={props.event_title}
              price_points={props.public_price_points}
              primary_price_point={props.primary_price_point}
              primary_camp_price_cents={props.primary_price_cents}
              date_range={props.date_range}
              csrfToken={document
                .querySelector("meta[name=csrf-token]")
                .getAttribute("content")}
              camper_identifier={camperIdentifier}
              previousStep={previousStep}
              nextStep={nextStep}
              campers={campers}
            />
          </div>
        );
      default:
        return null;
    }
  };