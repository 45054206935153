import React, { useState, useEffect } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AttendeeProfileList from './attendee_profile_list.js';

const ExistingAttendeeForm = ({ existing_profiles, onExistingCamperSelect, saveValues, nextStep, buttonStyles }) => {

  const [state, setState] = useState({});

  useEffect(() => {
  }, []);

  const valid = () => {
    return true;
  };

  const handleToggleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.checked }));
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const handleDateChange = (date, name) => {
    setState((prevState) => ({ ...prevState, [name]: date }));
  };

  const updateFormsBasedOnProfileSelect = (camperProfile) => {
    console.log(camperProfile)
    const [camperId] = camperProfile
    onExistingCamperSelect(camperId)
    nextStep();
  }

  const saveAndContinue = (e) => {
    e.preventDefault();
    saveValues(state);
    nextStep();
  };

  return (
    <form className={"new-step-form"} autoComplete={"off"}>
      <div className="row">
        <AttendeeProfileList onExistingAttendeeProfileSelect={updateFormsBasedOnProfileSelect} profilesJson={existing_profiles} />
      </div>
      <button
        type={"button"}
        className={"button button-primary create"}
        style={buttonStyles}
        onClick={saveAndContinue}
      >
        {"Register New Attendee"}
      </button>
    </form>
  );
};

export default ExistingAttendeeForm;
