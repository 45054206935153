import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        columns: [
                 { data: 'status' }, // Corresponds to link_to_registration_with_status
                 { data: 'registration_date' }, // Corresponds to registration_date_time_zoned
                 { data: 'first_name' }, // Corresponds to camper_first_name
                 { data: 'last_name' }, // Corresponds to camper_last_name
                 { data: 'age' }, // Corresponds to display_age
                 { data: 'switchable_data_point' }, // Unspecified in old view; replace with actual data point
                 { data: 'emergency_contact' }, // May need custom rendering for balloon tooltip
                 { data: 'physician' }, // May need custom rendering for balloon tooltip
                 { data: 'price_point' }, // Corresponds to status_text
                 { data: 'medical_notes' }, // Corresponds to medications and conditions
                 { data: 'waiver_form' } // Corresponds to medical_form_link
             ],
        ajax: {
          url: $(this.tableTarget).data('source'),
        },
        paging: true,
        pageLength: -1,
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: 'SEARCH',
        },
        lengthMenu: [
          [25, 50, 100, -1],
          ['25', '50', '100', 'All'],
        ],
        dom: 'Blfrtip',
        buttons: [
          {
            extend: 'copyHtml5',
            text: '<i class="fas fa-copy"></i> Copy',
            titleAttr: 'Copy',
          },
          {
            extend: 'print',
            text: '<i class="fas fa-print"></i> Print',
            titleAttr: 'Print',
            title: 'camper-list-table' + Date.now(),
            exportOptions: {
              modifier: {
                selected: null,
              },
            },
          },
          {
            extend: 'csvHtml5',
            text: '<i class="fas fa-table"></i> CSV',
            titleAttr: 'CSV',
            title: 'camper-list-table' + Date.now(),
            exportOptions: {
              modifier: {
                selected: null,
              },
            },
          },
        ],
      });
    }
  }
}

