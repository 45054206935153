import { Controller } from "@hotwired/stimulus"
import zxcvbn from "zxcvbn"

export default class extends Controller {
  static targets = ["password", "meter", "text", "advice"]

  connect() {
    this.strength = {
      0: "Worst",
      1: "Bad",
      2: "Weak",
      3: "Good",
      4: "Strong"
    }
  }

  checkStrength() {
    let val = this.passwordTarget.value
    let result = zxcvbn(val)
    let suggestionString = result.feedback.suggestions.join(" ")

    this.meterTarget.value = result.score
    if (val !== "") {
      this.textTarget.innerHTML = "Password Strength: " + this.strength[result.score]
      this.adviceTarget.innerHTML = suggestionString
    } else {
      this.textTarget.innerHTML = ""
      this.adviceTarget.innerHTML = ""
    }
  }
}

