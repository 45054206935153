// app/javascript/controllers/financial_overview_controller.js
import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
  static values = { url: String };

  downloadReport(event) {
    event.preventDefault(); // Prevent the link from navigating.

    // You can access the URL directly from the element's `href` attribute or a data attribute.
    const reportUrl = this.urlValue || this.element.getAttribute("href");

    fetch(reportUrl, {
      method: 'GET',
      headers: {
        'Accept': 'application/octet-stream', // Adjust based on your response type
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      if (!response.ok) throw new Error('Failed to download report');
      return response.blob();
    }).then(blob => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.download = "financial_overview.csv"; // Set the filename here.
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(downloadUrl);

      Toastify({
        text: "Financial Overview downloaded successfully.",
        duration: 4000,
        close: true,
        position: "right",
        className: "success",
        stopOnFocus: true,
      }).showToast();
    }).catch(error => {
      Toastify({
        text: error.message,
        duration: 4000,
        close: true,
        position: "right",
        className: "error",
        stopOnFocus: true,
      }).showToast();
    });
  }
}
