import React, { useState, useEffect } from "react";

const FullRefundForm = (props) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
    setToken(csrf);
  }, []);

  const formatAmount = (amount) => {
    let dollars = amount / 100;
    return dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    document.querySelector(".refund-form").submit();
  };

  const {
    registration_id,
    camper_name,
    registrant,
    registrant_email,
    registrant_phone_number,
    camp_title,
    date_range,
    amount_paid,
    transaction_id,
    buttonStyles,
  } = props;

  return (
    <form
      className="refund-form"
      id="full-refund"
      method="POST"
      action={`/admin/registrations/${registration_id}/full_payment_refund`}
      onSubmit={handleSubmit}
    >
      <div className="heading">
        <h1 className="form-heading">Confirm Full Refund</h1>
      </div>
      <div className="refund-recap">
        <div className="refund-info-box">
          <p className="participant-info">Participant:</p>
          <p className="participant-name">{props.camper_name}</p>
          <p className="field-description">Primary Contact:</p>
          <p className="field-content">{props.registrant}</p>
          <p className="field-bullet">{props.registrant_email}</p>
          <p className="field-bullet">{props.registrant_phone_number}</p>
        </div>
        <div className="refund-info-box">
          <p className="field-description">Event Information:</p>
          <p className="field-content">{props.camp_title}</p>
          <p className="field-bullet">{props.date_range}</p>
        </div>
        <div className="refund-info-box">
          <p className="field-description">Amount Paid:</p>
          <p className="field-content">
            {formatAmount(props.amount_paid)}
          </p>
        </div>
      </div>
      <div className="refund-row">
        <input type="hidden" key="utf8" name="utf8" value="✓" />
        <input
          type="hidden"
          key="authenticity_token"
          name="authenticity_token"
          value={token}
        />
        <input
          type="hidden"
          name="refund[transaction_id]"
          value={props.transaction_id}
        />
        <input
          type="hidden"
          name="refund[registration_id]"
          value={props.registration_id}
        />
      </div>
      <div className="button-row">
        <button
          type="submit"
          className="button button-primary refund-button"
          style={props.buttonStyles}
          onClick={handleSubmit}
        >
          Confirm Refund
        </button>
      </div>
    </form>
  );
};

export default FullRefundForm;
