// src/components/BirthdateInput.js
import React from "react";

const DateInput = ({ name, label, value, onChange }) => {
  return (
    <div>
      <label>{label}</label>
      <input type="date" name={name} value={value} onChange={onChange} />
    </div>
  );
};

export default DateInput;
