// src/components/TextInput.js
import React from 'react';

const TextInput = ({ name, value, placeholder, errorMessage, minLength, onChange, onBlur }) => {
  return (
    <input
      type="text"
      className={`${name} full-field`}
      placeholder={placeholder}
      required
      data-error-message={errorMessage}
      data-min-length={minLength}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

export default TextInput;
