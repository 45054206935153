import React from 'react';

const AttendeeProfileCard = ({ profile, onClick }) => {
  const [id, firstName, lastName, updatedAt] = profile;

  return (
    <div className="attendee-card">
      <h3>{`${firstName} ${lastName}`}</h3>
      <p aria-label={`profile last updated at`}>Last Updated: {new Date(updatedAt).toLocaleDateString()}</p>
      <button onClick={onClick} className="select-profile-button" aria-label={`Select profile for ${firstName} ${lastName}`}>Select Profile</button>
    </div>
  );
};

export default AttendeeProfileCard;
