import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    if (!this.hasTableTarget) return;
    
    $(this.tableTarget).DataTable({
      responsive: true,
      retrieve: true,
      autoWidth: false,
      processing: true,
      serverSide: true,
      paging: true,
      pageLength: 100,
      ajax: {
        url: $(this.tableTarget).data("source"),
      },
      columns: [
        { data: "last_name" },
        { data: "first_name" },
        { data: "email" },
        { data: "primary_phone" },
        { data: "settings" },
        { data: "admin" },
      ],
      buttons: [
        {
          extend: "copyHtml5",
          text: '<i class="fas fa-copy"></i>',
          titleAttr: "Copy",
        },
        {
          extend: "print",
          text: '<i class="fas fa-print"></i> Print',
          titleAttr: "Print",
          title: "admin-user-table-" + Date.now(),
        },
        {
          extend: "csvHtml5",
          text: '<i class="fas fa-table"></i> CSV',
          titleAttr: "CSV",
          title: "admin-user-table-" + Date.now(),
        },
      ],
      language: {
        search: '<i class="fa fa-search"></i>',
        searchPlaceholder: "SEARCH",
      },
    });
  }
}
