import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
  static targets = ["form"];

  submitReport(event) {
    event.preventDefault(); // Prevent the form from submitting traditionally

    const form = this.formTarget;
    const selectedYear = form.querySelector('select[name="year"]').value; // Assumes there's a select field named 'year'
    const url = new URL(form.action);
    const formData = new FormData(form);

    // Append form data to the URL search parameters for GET request
    formData.forEach((value, key) => url.searchParams.append(key, value));

    // Perform the fetch request
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      if (response.ok) return response.blob();
      throw new Error('Failed to generate report');
    }).then(blob => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = `annual_report_${selectedYear}.csv`; // Dynamically sets the filename based on the selected year
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(blobUrl);

      Toastify({
        text: "Report generated successfully.",
        duration: 4000,
        close: true,
        position: "right",
        className: "success", // Adjust className as needed
        stopOnFocus: true,
      }).showToast();
    }).catch(error => {
      Toastify({
        text: error.message,
        duration: 4000,
        close: true,
        position: "right",
        className: "error", // Adjust className as needed
        stopOnFocus: true,
      }).showToast();
    });
  }
}
