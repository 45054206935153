import React, { useState, useEffect } from 'react';
import CartRegistration from './cart_registration.js';

const ShoppingCart = (props) => {
  const [grandTotal, setGrandTotal] = useState(0);
  const [pricePoints, setPricePoints] = useState([]);

  useEffect(() => {
    const parsedPricePoints = JSON.parse(props.price_points);
    setPricePoints(parsedPricePoints);
  }, [props.price_points]);

  const calculateTotalPriceCents = () => {
    const { registrations } = props;
  };

  const pricePointDataById = (id) => {
    const price_point_data = pricePoints.find((price_point) => price_point.id === id);
    const { title, price_cents } = price_point_data;
    const price = formatCurrency(price_cents / 100);
    return { title, price };
  };

  const formatCurrency = (number) => {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  };

  const { registrations } = props;

  return (
    <div className="shop-cart">
      <h3 className="cart-heading">Your Order</h3>
      <div className="registrations-container">
        {Array.from(registrations).map((registration, key) => (
          <CartRegistration
            key={key}
            first_name={registration.attendee_information.first_name}
            last_name={registration.attendee_information.last_name}
            price_point_data={pricePointDataById(registration.price_point_id)}
          />
        ))}
      </div>
      <p>{grandTotal}</p>
    </div>
  );
};

export default ShoppingCart;
