import React, { useState } from "react";

const UtepLiabilityWaiverForm = (props) => {
  const [state, setState] = useState({
    checked: false,
    media_checked: false,
    liability_waiver: false,
    liability_waiver_initials: "",
    liability_waiver_signature: "",
    media_authorization: false,
    signature_image: null,
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const isValid = () => {
    return state.checked && state.liability_waiver_signature;
  };

  const setTodaysDate = () => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString("en-US", options);
  };

  const setUpInitialsForm = () => {
    if (props.collect_initials){
      return(<div className={"camper-info-row signature-info-box"}>
          <div className={"lw-signature-label"}>
            {"Initialed by " +
              `${props.signature_name}` +
              " on " +
              `${setTodaysDate()}`}
          </div>
          <div className={"camper-info-row lw-signature-container"}>
            <div className="signature-row">
              <i className="fas fa-pen-nib pen-polygon"></i>
              <input
                type={"text"}
                className="signature-input"
                name={"liability_waiver_initials"}
                value={state.liability_waiver_initials}
                onChange={handleChange}
              />
            </div>
            <div className="signature-row">
              <span className="enter-your-name">
                {"Please enter your initials"}
              </span>
            </div>
          </div>
        </div>)
    }
  }

  const handleChecked = (e) => {
    setState((prevState) => ({
      ...prevState,
      checked: e.target.checked,
      liability_waiver: e.target.checked,
    }));
  };

  const handleMediaChecked = (e) => {
    setState((prevState) => ({
      ...prevState,
      media_checked: e.target.checked,
      media_authorization: e.target.checked,
    }));
  };

  const handleCamperSubmit = (e) => {
    e.preventDefault();
    const newData = {
      liability_waiver: state.checked,
      liability_waiver_signature: state.liability_waiver_signature,
      liability_waiver_initials: state.liability_waiver_initials,
      custom_data: {
        ...props.custom_data,
        media_authorization: state.media_checked,
      }
    };
    props.saveValues(newData);
    return props.nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <div className={"buck-test"}>
        <h1 className="form-heading">{"Step 4. Event Liability Waiver and Media Authorization"}</h1>
      </div>
      <div className={"camper-info-row signature-info-box"}>
        <div className={"waiver-text"}>{props.waiver}</div>
      </div>
      <div className={"camper-info-row signature-info-box"}>
        <span className={"digi-sig"}>
          {"Digital Signature - I have read and agreed to the above waiver "}
        </span>
        <input
          type={"checkbox"}
          defaultChecked={state.checked}
          name={"liability_waiver"}
          value={state.liability_waiver}
          onChange={handleChecked}
        />
      </div>
      <div className={"camper-info-row signature-info-box"}>
        <span className={"digi-sig"}>
          {"Media Authorization (optional) - I authorize The University of Texas at El Paso, and those acting pursuant to its authority in accordance with this this Media Consent and Release."}
        </span>
        <input
          type={"checkbox"}
          defaultChecked={state.media_checked}
          name={"media_authorization"}
          value={state.media_authorization}
          onChange={handleMediaChecked}
        />
      </div>
      <div className={"camper-info-row"}>{setUpInitialsForm()}</div>
      <div className={"camper-info-row signature-info-box"}>
        <div className={"lw-signature-label"}>
          {"Signed by " +
            `${props.signature_name}` +
            " on " +
            `${setTodaysDate()}`}
        </div>
        <div className={"camper-info-row lw-signature-container"}>
          <div className="signature-row">
            <i className="fas fa-pen-nib pen-polygon"></i>
            <input
              type={"text"}
              className="signature-input"
              name={"liability_waiver_signature"}
              value={state.liability_waiver_signature}
              onChange={handleChange}
            />
          </div>
          <div className="signature-row">
            <span className="enter-your-name">
              {"Enter " + `${props.signature_name}` + " to sign"}
            </span>
          </div>
        </div>
      </div>
      <button
        type={"submit"}
        className={"button button-primary create"}
        disabled={!isValid()}
        style={props.buttonStyles}
        onClick={handleCamperSubmit}
      >
        Next Step
      </button>
    </form>
  );
};

export default UtepLiabilityWaiverForm;
