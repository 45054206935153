// src/components/TshirtSize.js
import React from "react";

const TshirtField = ({ name, label, value, onChange }) => {
  const sizes = ["XS", "S", "M", "L", "XL", "XXL"];

  return (
    <div>
      <span>{label}</span>
      <label>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        <option value="">Select a size</option>
        {sizes.map((size) => (
          <option key={size} value={size}>
            {size}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TshirtField;
