import React, { useCallback } from "react";

const YearsOfTrainingField = ({ years_of_training, onChange }) => {
  const handleChange = useCallback(
    (event) => {
      onChange(`${event.target.value} years`);
    },
    [onChange]
  );

  const setUpOptions = () => {
    let results = [];
    for (let years = 0; years <= 20; years++) {
      results.push(
        <option value={years} key={years}>
          {`${years} years`}
        </option>
      );
    }
    return results;
  };

  return (
    <div className="year-select-container">
      <span className="error-span"></span>
      <label className="years_of_training">Years of Training</label>
      <div className="camper-info-row">
        <select
          className="third-field"
          name="years_of_training"
          onChange={handleChange}
          value={years_of_training}
        >
          {setUpOptions()}
        </select>
      </div>
    </div>
  );
};

export default YearsOfTrainingField;
