import React, { useState } from "react";

const EmailInput = ({ name, label, value, onChange }) => {
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const email = e.target.value;
    const isValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

    setError(isValid ? null : "Please enter a valid email address");
    onChange({ target: { name, value: email } });
  };

  const inputId = `email-input-${name}`;
  const errorId = `email-error-${name}`;

  return (
    <div className="form-field-container">
      <label htmlFor={inputId}>{label.toUpperCase()}</label>
      <input
        type="email"
        id={inputId}
        value={value}
        onChange={handleChange}
        placeholder="example@example.com"
        aria-invalid={!!error}
        aria-describedby={error ? errorId : undefined}
      />
      {error && <p id={errorId} style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default EmailInput;
