import React, { useState, useEffect } from "react";

const HeightInput = ({ formDataHeight, onChange }) => {
  // Initialize state
  const [feet, setFeet] = useState("1");
  const [inches, setInches] = useState("0");

  useEffect(() => {
     if (formDataHeight) {
       const parts = formDataHeight.match(/\d+/g).map(String);
       if (parts.length === 2) { // E.g., "5 ft 11 in"
         setFeet(parts[0]); // Update feet
         setInches(parts[1]); // Update inches
       }
     }
   }, [formDataHeight]);

  const handleFeetChange = (event) => {
    const newFeet = event.target.value;
    setFeet(newFeet);
    const height = `${newFeet}ft ${inches}in`;
    onChange({ target: { name: 'height', value: height } });
  };

  const handleInchesChange = (event) => {
    const newInches = event.target.value;
    setInches(newInches);
    const height = `${feet}ft ${newInches}in`;
    onChange({ target: { name: 'height', value: height } });
  };

  const feetOptions = Array.from({ length: 8 }, (_, i) => (
    <option value={i + 1} key={i + 1}>
      {`${i + 1} ft`}
    </option>
  ));

  const inchesOptions = Array.from({ length: 12 }, (_, i) => (
    <option value={i} key={i}>
      {`${i} in`}
    </option>
  ));

  return (
    <div className="height-select-container">
      <span className="error-span"></span>
      <label className="height">Height</label>
      <div className="camper-info-row">
        <select
          className="third-field"
          name="feet"
          onChange={handleFeetChange}
          value={feet}
        >
          {feetOptions}
        </select>

        <select
          className="third-field"
          name="inches"
          onChange={handleInchesChange}
          value={inches}
        >
          {inchesOptions}
        </select>
      </div>
    </div>
  );
};

export default HeightInput;