import React, { useState } from "react";

const RefundPolicy = (props) => {
  const [state, setState] = useState({
  });

  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const isValid = () => {
    return state.checked && state.liability_waiver_signature;
  };

  const setTodaysDate = () => {
    let options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString("en-US", options);
  };

  return (
    <div>
      <div className={"buck-test"}>
        <h1 className="form-heading">{"Step 4. Event Liability Waiver"}</h1>
      </div>
      <div className={"camper-info-row signature-info-box"}>
        <div className={"waiver-text"}>{props.waiver}</div>
      </div>
      <div className={"camper-info-row signature-info-box"}>
        <span className={"digi-sig"}>
          {"Digital Signature - I have read and agreed to the above waiver "}
        </span>
        <input
          type={"checkbox"}
          defaultChecked={state.checked}
          name={"liability_waiver"}
          value={state.liability_waiver}
          onChange={handleChecked}
        />
      </div>
      <div className={"camper-info-row"}>{setUpInitialsForm()}</div>
      <div className={"camper-info-row signature-info-box"}>
        <div className={"lw-signature-label"}>
          {"Signed by " +
            `${props.signature_name}` +
            " on " +
            `${setTodaysDate()}`}
        </div>
        <div className={"camper-info-row lw-signature-container"}>
          <div className="signature-row">
            <i className="fas fa-pen-nib pen-polygon"></i>
            <input
              type={"text"}
              className="signature-input"
              name={"liability_waiver_signature"}
              value={state.liability_waiver_signature}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
