import React from "react";

const GenderField = ({ gender, onChange }) => {
  // Set up an array of gender options
  const genderOptionsArray = () => {
    return ["Male", "Female", "Non Binary", "Prefer Not to Say"];
  };

  // Generate an array of option elements based on the genderOptionsArray
  const setUpOptions = () => {
    const results = [];
    for (let gender of genderOptionsArray()) {
      results.push(
        <option value={gender} key={gender}>
          {gender}
        </option>
      );
    }
    return results;
  };

  // Handle the onChange event for the select field
  const onFieldChange = (event) => {
    onChange(event.target.value);
  };

  // Render the component, including the select field and its options
  return (
    <div className="gender-field-container">
      <span className="gender-label">{"Gender"}</span>
      <select
        className={"gender-dropdown third-field"}
        name={"gender"}
        onChange={onFieldChange}
        value={gender}
      >
        {setUpOptions()}
      </select>
    </div>
  );
};

export default GenderField;
