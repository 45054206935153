import React, { useState, useEffect } from "react";
import axios from "axios";

const tokenDom = document.querySelector("meta[name=csrf-token]");
if (tokenDom) {
  const csrfToken = tokenDom.content;
  axios.defaults.headers.common["X-CSRF-Token"] = csrfToken;
}

const WaitlistButton = ({ waitlist_price_point_ids, price_point_id }) => {
  const [waitlisted, setWaitlisted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [waitlistMessage, setWaitlistMessage] = useState("Join Waitlist");

  useEffect(() => {
    if (waitlist_price_point_ids.includes(price_point_id)) {
      setWaitlistMessage("Waitlisted");
      setWaitlisted(true);
    }
  }, [waitlist_price_point_ids, price_point_id]);

  const updateAdminWaitlist = () => {
    setIsLoading(true);
    const waitlistEntry = {
      price_point_id: price_point_id,
    };
    axios
      .post("/waitlist_entries.json", { waitlist_entry: waitlistEntry })
      .then(() => {
        setWaitlistMessage("Waitlisted");
        setWaitlisted(true);
      })
      .finally(() => setIsLoading(false));
  };

  const onWaitlistButtonChange = () => {
    if (!waitlisted && !isLoading) {
      updateAdminWaitlist();
    }
  };

  return (
    <a
      className={`wait-btn ${waitlisted ? "waitlisted" : ""}`}
      onClick={onWaitlistButtonChange}
      disabled={isLoading}
    >
      {waitlistMessage}
    </a>
  );
};

export default WaitlistButton;