import React, { useState, useEffect } from "react";

import BirthDateDropdown from "./birth_date_dropdown.js";
import StateDropdown from "./state_dropdown.js";
import CountryCodeDropdown from "./country_code_dropdown.js";
import WaitlistModal from "./waitlist_modal.js";
import Cleave from "cleave.js/dist/cleave-react";
import GenderField from "./gender_field.js";

const JmuCamperInfoForm = (props) => {
  const [state, setState] = useState({
    showModal: null,
    first_name: "",
    last_name: "",
    birth_date: new Date("2004", "01", "01"),
    player_phone_number: "",
    emergency_contact: "",
    emergency_contact_country_code: "+1",
    emergency_contact_number: "",
    gender: "Female",
    grade: "",
    guardian_contact: "",
    guardian_contact_country_code: "+1",
    guardian_contact_number: "",
    guardian_contact_email: "",
    guardian_contact_relationship: "",
    email: "",
    address: "",
    city: "",
    state: "AL - Alabama",
    zip: "",
    price_points: JSON.parse(props.price_points),
  });

  useEffect(() => {
    console.log("alright alright alright.");
  }, []);

  const campFull = () => {
    return (
      state.price_points.length == createFullPricePointArray().length
    );
  };

  const genderFieldChanged = (selectedGender) => {
    setState(prevState => ({ ...prevState, gender: selectedGender }));
  };

  const stateDropdownChanged = (newState) => {
    setState(prevState => ({ ...prevState, state: newState }));
  };

  const countryCodeDropdownChanged = (newCode) => {
    setState(prevState => ({
      ...prevState,
      emergency_contact_country_code: newCode,
    }));
  };

  const guardianCountryCodeDropdownChanged = (newCode) => {
    setState(prevState => ({
      ...prevState,
      guardian_contact_country_code: newCode,
    }));
  };

  const dateChanged = (newDate) => {
    setState(prevState => ({ ...prevState, birth_date: newDate }));
  };

  const setGuardianFields = () => {
    if (props.collect_guardian_info) {
      return (
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <label className={"big-span"}>
            {"Guardian Contact Information: *"}
          </label>
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Name"}
            data-error-message={
              "Guardian Contact Name must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact"}
            ref={"guardian_contact"}
            minLength={5}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"full-field"}
            placeholder={"Guardian Relationship - Mother, Father, etc"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_relationship"}
            ref={"guardian_contact_relationship"}
            minLength={2}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <div className={"half"}>
            <CountryCodeDropdown
              value={state.guardian_contact_country_code}
              onChange={guardianCountryCodeDropdownChanged}
            />
            <Cleave
              type={"tel"}
              id={""}
              className={"ecn phone-us field two-thirds-field"}
              size={10}
              pattern={"[0-9]{10}"}
              name={"guardian_contact_number"}
              ref={"guardian_contact_number"}
              placeholder={"Guardian Contact #"}
              data-error-message={
                "Guardian Phone must be 10 characters. If you are still having issues, clear the field and try again."
              }
              data-min-length={"10"}
              options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
              onChange={handlePhoneChange}
              onBlur={checkInput}
            />
          </div>
          <input
            type={"email"}
            className={"half full-field"}
            placeholder={"Guardian Email"}
            data-error-message={
              "Guardian Relationship must be longer than the specified number of characters."
            }
            data-min-length={"5"}
            name={"guardian_contact_email"}
            ref={"guardian_contact_email"}
            minLength={5}
            required={true}
            defaultValue={props.emergency_contact}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      );
    }
  };

  const setFullPricePointFlag = () => {
    const pricePoints = state.price_points;
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        return true;
      }
    }
    return false;
  };

 const createOpenPricePointArray = () => {
    const pricePoints = state.price_points;
    let openPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count <= pricePoints[i].capacity) {
        openPricePoints.push(pricePoints[i]);
      }
    }
    return openPricePoints;
  };

  const createFullPricePointArray = () => {
    const pricePoints = state.price_points;
    let fullPricePoints = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        fullPricePoints.push(pricePoints[i]);
      }
    }
    return fullPricePoints;
  };

  const checkInput = (e) => {
    let itemClass = e.target.className;
    let item = e.target;
    // console.log(e.target.type)
    let val = e.target.value;
    let errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      var errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  const validateEmail = (email) => {
    var emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  const valid = () => {
    if (
      validateLength(state.first_name, 1) &&
      validateLength(state.last_name, 1) &&
      validateEmail(state.email) &&
      validateLength(state.emergency_contact, 4) &&
      validateLength(state.address, 4) &&
      state.city &&
      validateLength(state.zip, 2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      first_name: state.first_name,
      last_name: state.last_name,
      birth_date: state.birth_date,
      emergency_contact: state.emergency_contact,
      emergency_contact_number: state.emergency_contact_number,
      gender: state.gender,
      guardian_contact: state.guardian_contact,
      guardian_contact_number: state.guardian_contact_number,
      guardian_contact_email: state.guardian_contact_email,
      guardian_contact_relationship: state.guardian_contact_relationship,
      email: state.email,
      player_phone_number: state.player_phone_number,
      address: state.address,
      city: state.city,
      state: state.state,
      zip: state.zip,
    };
    props.saveValues(data);
    return props.nextStep();
  };


  return (
    <form className={"new-step-form"} autoComplete={"off"}>
      <WaitlistModal
        waitlist_price_point_ids={props.waitlist_price_point_ids}
        button_style={props.buttonStyles}
        camp_full={campFull()}
        show_modal={setFullPricePointFlag()}
        full_price_points={createFullPricePointArray()}
        open_price_points={createOpenPricePointArray()}
      />
      <div className={"buck-test"}>
        <h1 className={"form-heading"}>{"Step 1. Attendee Information"}</h1>
      </div>
      <div className={"camper-info-row"}>
        <label className={"big-span"}></label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"First Name *"}
          data-error-message={"First Name must be at least two characters"}
          data-min-length={"2"}
          name={"first_name"}
          ref={"first_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Last Name *"}
          data-error-message={"Last Name must be at least two characters"}
          data-min-length={"2"}
          name={"last_name"}
          ref={"last_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>
        <label htmlFor={"email"}>{"Player Email: *"}</label>
        <span className={"error-span"}></span>
        <input
          type={"email"}
          className={"camper-email full-field"}
          placeholder={"Player Email *"}
          data-error-message={
            "Email must be valid and longer than the specified number of characters"
          }
          data-min-length={"5"}
          name={"email"}
          ref={"email"}
          minLength={5}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
        <div className={"half"}>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"grade full-field"}
            placeholder={"2023 Fall Grade"}
            data-error-message={"The Grade field needs be filled out"}
            data-min-length={"1"}
            name={"grade"}
            ref={"grade"}
            minLength={1}
            required={true}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
        <div className={"half"}>
          <BirthDateDropdown
            onChange={dateChanged}
            birth_date={state.birth_date}
          />
        </div>
        {props.category == "coed" && (
          <div className={"camper-info-row"}>
            <GenderField
              onChange={genderFieldChanged}
              gender={state.gender}
            />
          </div>
        )}
      </div>
      <div className={"camper-info-row"}>
        <label className={"big-span"}>
          {"Emergency Contact Information: *"}
        </label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Emergency Contact Full Name"}
          data-error-message={
            "Emergency Contact Name and Number must be longer than the specified number of characters."
          }
          data-min-length={"4"}
          name={"emergency_contact"}
          ref={"emergency_contact"}
          minLength={4}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="half">
        <CountryCodeDropdown
          value={state.emergency_contact_country_code}
          onChange={countryCodeDropdownChanged}
        />
        <Cleave
          id="phone-mask"
          className={"ecn phone-us field two-thirds-field"}
          type={"tel"}
          size={10}
          pattern={"[0-9]{10}"}
          name={"emergency_contact_number"}
          ref={"emergency_contact_number"}
          placeholder={"Emergency Contact #"}
          data-error-message={
            "EC Phone must be 10 characters. If you are still having issues, clear the field and try again."
          }
          data-min-length={"10"}
          options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
          onChange={handlePhoneChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>{setGuardianFields()}</div>
      <div className={"camper-info-row address"}>
        <label>{"Primary Address: *"}</label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"address full-field"}
          placeholder={"Street Address"}
          data-error-message={"Please enter a valid address"}
          data-min-length={"4"}
          name={"address"}
          ref={"address"}
          minLength={4}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row address"}>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"city full-field"}
          placeholder={"City"}
          data-error-message={
            "City must be longer than the specified number of characters."
          }
          data-min-length={"4"}
          name={"city"}
          ref={"city"}
          minLength={4}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="camper-info-row">
        <div className="error-span"></div>
        <StateDropdown
          value={state.state}
          onChange={stateDropdownChanged}
        />
        <input
          type={"text"}
          className={"zip field third-field"}
          placeholder={"Zip Code (i.e. 20008)"}
          data-error-message={"Please enter a valid zip code."}
          data-min-length={"2"}
          name={"zip"}
          ref={"zip"}
          minLength={2}
          maxLength={10}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <button
        type={"submit"}
        className={"button button-primary create"}
        style={props.buttonStyles}
        disabled={!valid()}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
}
export default JmuCamperInfoForm
