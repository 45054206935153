import { Controller } from "@hotwired/stimulus";
import Toastify from "toastify-js";

export default class extends Controller {
  static targets = ["form"];

  submitReport(event) {
    event.preventDefault(); // Prevent the form from submitting traditionally

    const form = this.formTarget;
    const url = new URL(form.action);
    const formData = new FormData(form);
    const year = formData.get('year'); // Get the selected year from the form

    // Append form data to the URL search parameters for GET request
    formData.forEach((value, key) => url.searchParams.append(key, value));

    // Perform the fetch request
    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'text/html',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error('Failed to generate report');
      }
      return response.blob();
    }).then(blob => {
      // Create a URL for the blob
      const blobUrl = window.URL.createObjectURL(blob);

      // Create an <a> element and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = `key-metrics-report-${year}.csv`; // Include the year in the filename
      document.body.appendChild(downloadLink); // Required for Firefox
      downloadLink.click();
      document.body.removeChild(downloadLink); // Clean up
      window.URL.revokeObjectURL(blobUrl); // Free up memory

      // Use Toastify for success message
      Toastify({
        text: "Report downloaded successfully.",
        duration: 4000,
        close: true,
        position: "right",
        className: "success",
        stopOnFocus: true,
      }).showToast();
    }).catch(error => {
      // Use Toastify for error message
      Toastify({
        text: error.message,
        duration: 4000,
        close: true,
        position: "right",
        className: "error",
        stopOnFocus: true,
      }).showToast();
    });
  }
}