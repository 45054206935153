import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: false,
        paging: true,
        pageLength: 100,
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: "SEARCH",
        },
      });
    }
  }
}
