import React, { useState } from 'react';

const UtepTransportAuthorizationForm = ({ saveValues, nextStep }) => {
  const [formData, setFormData] = useState({
    custom_data: {
      names: ['', '', ''],
      relationships: ['', '', ''],
      licenses: ['', '', ''],
      selfTransportAuthorization: false,
      parentGuardianInitial: '',
    },
    signature: '',
  });

  const handleInputChange = (field, subfield, index) => (e) => {
    const updatedFormData = { ...formData };
    if (subfield) {
      updatedFormData[field][subfield][index] = e.target.value;
    } else {
      updatedFormData[field] = e.target.value;
    }
    setFormData(updatedFormData);
  };

  const handleCheckboxChange = (e) => {
    const updatedFormData = { ...formData };
    updatedFormData.custom_data.selfTransportAuthorization = e.target.checked;
    setFormData(updatedFormData);
  };

  const handleInitialChange = (e) => {
    const updatedFormData = { ...formData };
    updatedFormData.custom_data.parentGuardianInitial = e.target.value;
    setFormData(updatedFormData);
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    saveValues(formData);
    nextStep();
  };

  return (
    <div className="authorization-form new-step-form">
      <h2>AUTHORIZATION FOR DROP OFF, PICKUP & TRANSPORTATION:</h2>
      <p>
        I hereby authorize the following individuals to either drop off or pick up the named participant. Each person listed below will be informed that it is their responsibility to show proof of identity to the designated course/activity instructor.
      </p>
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="camper-info-row authorization-entry">
          <div>
            Full Name:
            <input
              type="text"
              value={formData.custom_data.names[index]}
              onChange={handleInputChange('custom_data', 'names', index)}
            />
          </div>
          <div>
            Relationship:
            <input
              type="text"
              value={formData.custom_data.relationships[index]}
              onChange={handleInputChange('custom_data', 'relationships', index)}
            />
          </div>
          <div>
            Driver’s License#:
            <input
              type="text"
              value={formData.custom_data.licenses[index]}
              onChange={handleInputChange('custom_data', 'licenses', index)}
            />
          </div>
        </div>
      ))}
      <div className="self-transport-section">
        <div style={{margin: "20px"}}>
          <input
            type="checkbox"
            checked={formData.custom_data.selfTransportAuthorization}
            onChange={handleCheckboxChange}
          />
          I do authorize The University of Texas at El Paso to release Participant to transport him/herself to and/or from designated course/activity (i.e. walking, biking, public transportation, own vehicle).
        </div>
        <div className={"camper-info-row"}>
          Parent/Guardian Initial:
          <input
            type="text"
            value={formData.custom_data.parentGuardianInitial}
            onChange={handleInitialChange}
            maxLength={3}
            size={3}
          />
        </div>
      </div>
      <div className="camper-info-row signature-section">
        Signature:
        <input type="text" value={formData.signature} onChange={(e) => handleInputChange('signature')(e)} placeholder="Your Signature" />
      </div>
      <button className={"button button-primary create"} onClick={saveAndContinue}>Next Step</button>
    </div>
  );
};

export default UtepTransportAuthorizationForm;