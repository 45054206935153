// Rails and ActiveStorage Initialization
import Rails from "@rails/ujs";
import * as ActiveStorage from "activestorage";

// Ensure Rails and ActiveStorage are started first
Rails.start();
ActiveStorage.start();

// React and ReactRailsUJS Initialization
import React from "react";
import ReactDom from "react-dom";
import ReactRailsUJS from "react_ujs";
import PropTypes from "prop-types";

const componentRequireContext = require.context("../components", true);
ReactRailsUJS.useContext(componentRequireContext);
window.componentRequireContext = componentRequireContext;
ReactRailsUJS.mountComponents();

// Stimulus Initialization
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

window.Stimulus = Application.start();
const context = require.context("../controllers", true, /\.js$/);
Stimulus.load(definitionsFromContext(context));

// Third-Party Libraries
import selectize from "selectize";
import DataTable from "datatables.net-dt";
import 'datatables.net-buttons';
import 'datatables.net-responsive-dt';
import "jszip";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";

// Styles and Assets
import "swiper/swiper-bundle.css";
import "toastify-js/src/toastify.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import "normalize-css";
require.context("../images", true);
import "../stylesheets/application.scss";


// Other Libraries
import "trix";
import "@rails/actiontext";
import "chartkick";
import "chart.js";
import "youtube";

// Custom Scripts
async function loadCustomScripts() {
  try {
    await import("../src/tippy_popovers");
    await import("../src/landing_page_slider");
    await import("../src/datatiles");
    await import("../src/uppy");
    await import("../src/uppy_document");
    await import("../src/camps");
    await import("../src/mailer_dashboard");
    await import("../src/sms_notifications");
    await import("../src/date_select_wrapper.jsx");
  } catch (error) {
    console.error("Error loading custom scripts:", error);
  }
}

function ensureScriptsLoaded() {
  if (document.readyState === "loading") {
    ["DOMContentLoaded", "turbo:load"].forEach(event => {
      document.addEventListener(event, loadCustomScripts);
    });
  } else {
    loadCustomScripts();
  }
}

ensureScriptsLoaded();


// Additional custom code can be placed here if needed
// Example function for modifying PDF (commented out for now)
/*
async function modifyPdf() {
  const url = 'https://pdf-lib.js.org/assets/with_update_sections.pdf'
  const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

  const pdfDoc = await PDFDocument.load(existingPdfBytes)
  const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

  const pages = pdfDoc.getPages()
  const firstPage = pages[0]
  const { width, height } = firstPage.getSize()
  firstPage.drawText('This text was added with JavaScript!', {
    x: 5,
    y: height / 2 + 300,
    size: 50,
    font: helveticaFont,
    color: rgb(0.95, 0.1, 0.1),
    rotate: degrees(-45),
  })

  const pdfBytes = await pdfDoc.save()
}

document.addEventListener('DOMContentLoaded', () => {
  let coolButton = document.querySelector('#coolio')
  let filledOutPdf = document.querySelector("iframe")
  let url = filledOutPdf.src
  coolButton.addEventListener("click", (event) => {
      event.preventDefault()
      console.log('cool')
      flattenForm(url)
  });
})

async function flattenForm(pdfUrl) {
  const formPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(formPdfBytes)
  const form = pdfDoc.getForm()
  // Example form fields
  form.getTextField('Participant').setText("Howard E O'Leary");

  form.flatten();

  const pdfBytes = await pdfDoc.save()
  download(pdfBytes, "pdf-lib_form_creation_example.pdf", "application/pdf");
}

async function fillForm() {
  let pdfUrl = "https://firewood-misc-storage.s3.us-east-1.amazonaws.com/combined.pdf"

  const formPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
  const pdfDoc = await PDFDocument.load(formPdfBytes)
  const form = pdfDoc.getForm()

  console.log(form)
  console.log(form.acroForm)

  const pdfBytes = await pdfDoc.save()
}
fillForm()
*/
