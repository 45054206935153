import React, { useState, useEffect } from "react";
import Cleave from "cleave.js/dist/cleave-react";

const PartialRefundForm = (props) => {
  const [amountToRefund, setAmountToRefund] = useState(0);
  const [token, setToken] = useState("");

  useEffect(() => {
    const csrf = document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
      setToken(csrf);
  }, []);

  const valid = () => {
    return validateRefundAmount(amountToRefund);
  };

  const validateRefundAmount = (field) => {
    return true;
  };

  const formatAmount = (amount) => {
    let dollars = amount / 100;
    return dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  const handleChange = (e) => {
    setAmountToRefund(e.target.rawValue);
  };

  const handleSubmit = (e) => {
    const form = document.getElementById("partial-refund");
    form.submit();
  };

  const {
    registration_id,
    camper_name,
    registrant,
    registrant_email,
    registrant_phone_number,
    camp_title,
    date_range,
    amount_paid,
    transaction_id,
    buttonStyles,
  } = props;

  return (
    <form
      className="refund-form"
      id="partial-refund"
      method="POST"
      action={`/admin/registrations/${registration_id}/partial_payment_refund`}
    >
      <div className="heading">
        <h1 className="form-heading">Confirm Partial Refund</h1>
      </div>
      <div className="refund-recap">
        <div className="refund-info-box">
          <p className="participant-info">Participant:</p>
          <p className="participant-name">{camper_name}</p>
          <p className="field-description">Primary Contact:</p>
          <p className="field-content">{registrant}</p>
          <p className="field-bullet">{registrant_email}</p>
          <p className="field-bullet">{registrant_phone_number}</p>
        </div>
        <div className="refund-info-box">
          <p className="field-description">Event Information:</p>
          <p className="field-content">{camp_title}</p>
          <p className="field-bullet">{date_range}</p>
        </div>
        <div className="refund-info-box">
          <p className="field-description">Amount Paid:</p>
          <p className="field-content">
            {formatAmount(amount_paid)}
          </p>
        </div>
        <div className="refund-info-box">
          <p className="field-description">Amount To Refund:</p>
          <Cleave
              id="amount_to_refund"
              type="text"
              min="0"
              max={amount_paid}
              className={"field two-thirds-field"}
              name="refund[amount_to_refund]"
              data-name="amount_to_refund"
              placeholder={"Amount to Refund ($)"}
              options={{
                prefix: "$",
                numeral: true,
                rawValueTrimPrefix: true,
                numericOnly: true,
                noImmediatePrefix: false,
                numeralPositiveOnly: true,
              }}
              value={amountToRefund}
              onChange={handleChange}
            />
        </div>
         <div className="refund-row">
          <input type="hidden" key="utf8" name="utf8" value="✓" />
          <input
            type="hidden"
            key="authenticity_token"
            name="authenticity_token"
            value={token}
          />
          <input
            type="hidden"
            name="refund[transaction_id]"
            value={transaction_id}
          />
          <input
            type="hidden"
            name="refund[registration_id]"
            value={registration_id}
          />
      </  div>
        <div className="button-row">
          <button
            type="submit"
            className="button button-primary refund-button"
            style={props.buttonStyles}
            onClick={handleSubmit}
          >
            Confirm Refund
          </button>
        </div>
      </div>
    </form>)
}
export default PartialRefundForm