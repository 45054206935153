import React from "react";

const monthName = (monthNumber) => [
  "January", "February", "March",
  "April", "May", "June",
  "July", "August", "September",
  "October", "November", "December"
][monthNumber];

const DateDropdown = ({ name, label, value, onChange }) => {
  const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({ value: i, label: monthName(i) }));
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: 100 }, (_, i) => currentYear - i);

  const handleDateChange = (part) => (event) => {
    const newDate = new Date(value);
    if (part === 'year') {
      newDate.setFullYear(event.target.value);
    } else if (part === 'month') {
      newDate.setMonth(event.target.value);
    } else {
      newDate.setDate(event.target.value);
    }
    onChange(newDate);
  };

  return (
    <div className="date-dropdown-container">
      <label>{label}</label>
      <select value={value.getMonth()} onChange={handleDateChange('month')} name={`${name}_month`}>
        {monthOptions.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
      </select>
      <select value={value.getDate()} onChange={handleDateChange('day')} name={`${name}_day`}>
        {dayOptions.map((day) => <option key={day} value={day}>{day}</option>)}
      </select>
      <select value={value.getFullYear()} onChange={handleDateChange('year')} name={`${name}_year`}>
        {yearOptions.map((year) => <option key={year} value={year}>{year}</option>)}
      </select>
    </div>
  );
};

export default DateDropdown;
