import React, { useCallback, memo } from "react";

const createOptions = Array.from({ length: 21 }, (_, i) => i);

const YearsInSportField = ({ years_in_sport, onChange }) => {
  const handleChange = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <div className="year-select-container">
      <span className="error-span"></span>
      <label className="years-in-sport">Years in Sport</label>
      <div className="camper-info-row">
        <select
          className="third-field"
          name="years_in_sport"
          onChange={handleChange}
          value={years_in_sport}
        >
          {createOptions.map((year) => (
            <option value={year} key={year}>
              {`${year} years`}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default memo(YearsInSportField);