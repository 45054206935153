import React from 'react';

function TeammatesCollectionField({ teammates, setFormData }) {
  const handleTeammateChange = (index, event) => {
    const newTeammates = [...teammates];
    newTeammates[index].name = event.target.value;
    setFormData(prevState => ({
      ...prevState,
      teammates: newTeammates,
    }));
  };

  const handleAddTeammate = () => {
    setFormData(prevState => ({
      ...prevState,
      teammates: [...prevState.teammates, { name: '' }],
    }));
  };

  const handleRemoveTeammate = (index) => {
    setFormData(prevState => ({
      ...prevState,
      teammates: prevState.teammates.filter((_, i) => i !== index),
    }));
  };

  return (
    <div className="teammates-section">
      {teammates.map((teammate, index) => (
        <div key={index} className="teammate-group">
          <label htmlFor={`teammate-${index}`}>{`Teammate ${index + 1}:`}</label>
          <input
            type="text"
            name={`teammate-${index}`}
            id={`teammate-${index}`}
            value={teammate.name}
            placeholder={`Teammate ${index + 1} Name`}
            onChange={(event) => handleTeammateChange(index, event)}
            required={index === 0} // Assuming the first teammate is required
            aria-label={`Teammate ${index + 1} Name`}
            className="form-control"
          />
          {index > 0 && (
            <button type="button" onClick={() => handleRemoveTeammate(index)} className="btn-remove">
              Remove
            </button>
          )}
        </div>
      ))}
      {teammates.length < 8 && (
        <button type="button" onClick={handleAddTeammate} className="btn-add">
          Add Teammate
        </button>
      )}
    </div>
  );
}

export default TeammatesCollectionField;

