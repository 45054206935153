import React, { useState, useEffect } from "react";
import Cleave from "cleave.js/dist/cleave-react";

const UtepMedicalForm = (props) => {
  const [state, setState] = useState({
    physician: "",
    physician_contact_number: "",
    insurer: "",
    insurer_phone_number: "",
    group_number: "",
    medications: "",
    conditions: "",
    allergies: "",
    diabetes: false,
    asthma: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Update handleChange function
  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  // Update handleChecked function
  const handleChecked = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.checked }));
  };

  // Update handlePhoneChange function
  const handlePhoneChange = (e) => {
    const { name } = e.target;
    if (name === "guardian_contact_number") {
      setState((prevState) => ({
        ...prevState,
        [name]: state.guardian_contact_country_code + " " + e.target.value,
      }));
    } else if (name === "emergency_contact_number") {
      setState((prevState) => ({
        ...prevState,
        [name]: state.emergency_contact_country_code + " " + e.target.value,
      }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: e.target.value }));
    }
  };

  const valid = () => {
    return (
      validateLength(state.insurer_phone_number, 9) &&
      validateLength(state.insurer, 4) &&
      validateLength(state.group_number, 2)
    );
  };

  const validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  const checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    let newData = {
      physician: state.physician,
      physician_contact_number: state.physician_contact_number,
      insurer: state.insurer,
      insurer_phone_number: state.insurer_phone_number,
      group_number: state.group_number,
      allergies: state.allergies,
      medications: state.medications,
      conditions: state.conditions,
      diabetes: state.diabetes,
      asthma: state.asthma,
    };
    props.saveValues(newData);
    return props.nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <div className={"place"}>
        <h1 className={"form-heading"}>{"Step 2. Medical Information"}</h1>
        <div className={"camper-info-row"}>
          <label className={"up-size big-span"}>
            {"Insurance Information: *"}
          </label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"insurance` full-field"}
            placeholder={"Insurance Provider *"}
            required={true}
            data-error-message={
              "Insurance Provider must be filled out. No abbreviations please!"
            }
            data-min-length={"4"}
            name={"insurer"}
            value={state.insurer}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"group-number full-field"}
            placeholder={"Insurance Group / Policy Number *"}
            required={true}
            data-error-message={
              "Insurance Group / Policy Number must be more than the specified number of characters"
            }
            data-min-length={"3"}
            name={"group_number"}
            value={state.group_number}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
        <div className={"camper-info-form"}>
          <span className={"error-span"}></span>
          <Cleave
            id="phone-mask"
            className={"ecn phone-us field two-thirds-field"}
            type={"tel"}
            size={10}
            pattern={"[0-9]{10}"}
            name={"insurer_phone_number"}
            placeholder={"Insurer Contact Phone Number"}
            data-error-message={
              "Insurer Phone must be 10 characters. If you are still having issues, clear the field and try again."
            }
            data-min-length={"10"}
            options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: "-" }}
            onChange={handlePhoneChange}
            onBlur={checkInput}
          />
        </div>
        <div className={"health-container camper-info-row"}>
          <span className={"up-size"}>{"Health Overview:"}</span>
          <span className={"error-span"}></span>
          <div className={"camper-info-row"}>
            <textarea
              className={"meds full-field"}
              placeholder={"Please list any Medications..."}
              name={"medications"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.medications}
            ></textarea>
            <textarea
              rows={{ 5: 5 }}
              className={"conds full-field"}
              placeholder={"Please list any Medical Conditions - etc...."}
              name={"conditions"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.conditions}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <textarea
              rows={{ 5: 5 }}
              className={"allergiess full-field"}
              placeholder={"Please list any allergies...."}
              name={"allergies"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.allergies}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <div className={"check-split third-field"}>
              <span htmlFor={"diabetes"} className={"check-span up-size"}>
                {"Diabetes"}
              </span>
              <input
                type={"checkbox"}
                className={"diabetes"}
                name={"diabetes"}
                value={state.diabetes}
                onChange={handleChecked}
              />
            </div>
            <div className={"check-split third-field"}>
              <span htmlFor={"asthma"} className={"check-span up-size"}>
                {"Asthma"}
              </span>
              <input
                type={"checkbox"}
                className={"asthma"}
                name={"asthma"}
                value={state.asthma}
                onChange={handleChecked}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"camper-info-row"}>
        <button
          type={"button"}
          className={"button button-primary create"}
          disabled={!valid()}
          style={props.buttonStyles}
          onClick={saveAndContinue}
        >
          {"Next Step"}
        </button>
      </div>
      <div style={{margin: "20px",}} className={"camper-info-row"}>
        <p>If you have or suspect, a disability and need an accommodation, please contact our staff at (915) 747-7601 or DaGarcia72@utep.edu to request any special accommodations/needs.</p>
      </div>
    </form>
  );
};

export default UtepMedicalForm;

