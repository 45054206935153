import React, { useState, useEffect } from "react";

const monthName = (monthNumberStartingFromZero) => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
][monthNumberStartingFromZero];

// Helper function to parse a date string in the format "YYYY-MM-DD"
const parseDate = (dateString) => {
  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const day = parseInt(parts[2], 10);
  return new Date(year, month, day);
};

const BirthDateSelect = ({ birth_date, onChange }) => {
  const [date, setDate] = useState(parseDate(birth_date ? birth_date.toString() : "2000-01-01"));
  const [error, setError] = useState('');

  useEffect(() => {
    if (birth_date) {
      let newDate;
      if (typeof birth_date === 'string') {
        const parts = birth_date.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[2], 10);
        newDate = new Date(year, month, day);
      } else if (birth_date instanceof Date) {
        newDate = new Date(birth_date.getTime());
      } else {
        newDate = new Date();
        setError('Invalid date');
      }

      if (!isNaN(newDate)) {
        setDate(newDate);
      }
    }
  }, [birth_date]);

  const handleYearChange = (event) => {
    const newDate = new Date(date);
    newDate.setFullYear(event.target.value);
    setDate(newDate);
    onChange(newDate);
  };

  const handleMonthChange = (event) => {
    const newDate = new Date(date);
    newDate.setMonth(event.target.value);
    setDate(newDate);
    onChange(newDate);
  };

  const handleDayChange = (event) => {
    const newDate = new Date(date);
    newDate.setDate(event.target.value);
    setDate(newDate);
    onChange(newDate);
  };

  const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1);
  const monthOptions = Array.from({ length: 12 }, (_, i) => ({ value: i, label: monthName(i) }));
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: currentYear - 1940 + 1 }, (_, i) => currentYear - i);

  return (
    <div className="birth-date-container">
      <div className="birth-date">
        <span className="bd-text">Birthdate</span>
      </div>
      <br />
      {error && <div className="error">{error}</div>}
      <div className="birth-date-half">
        <select className="month-select third-field" value={date.getMonth()} onChange={handleMonthChange}>
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="birth-date-quarter">
        <select className="third-field" value={date.getDate()} onChange={handleDayChange}>
          {dayOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="birth-date-quarter">
        <select className="year-select third-field" value={date.getFullYear()} onChange={handleYearChange}>
          {yearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BirthDateSelect;
