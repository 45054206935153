import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";
import "data-confirm-modal";

import PricePointsController from "./price_points_controller";

export default class extends Controller {
  static targets = [
    "title",
    "startDate",
    "endDate",
    "startTime",
    "endTime",
    "description",
    "pricePointsContainer",
    "modal"
  ];

  validate(event) {
    event.preventDefault();

    let missingFields = [];

    if (!this.titleTarget.value.trim()) {
      missingFields.push({ id: "title_label", name: "Title" });
    }

    if (!this.startDateTarget.value.trim()) {
      missingFields.push({ id: "calendar-validation-message", name: "Start Date" });
    }

    if (!this.endDateTarget.value.trim()) {
      missingFields.push({ id: "calendar-validation-message", name: "End Date" });
    }

    if (!this.startTimeTarget.value.trim()) {
      missingFields.push({ id: "time-section", name: "Start Time" });
    }

    if (!this.endTimeTarget.value.trim()) {
      missingFields.push({ id: "time-section", name: "End Time" });
    }

    if (!this.descriptionTarget.value.trim()) {
      missingFields.push({ id: "description-label", name: "Description" });
    }

    // Validate price points using the price points controller

    const pricePointsController = this.application.getControllerForElementAndIdentifier(this.pricePointsContainerTarget, "price-points");
    const pricePointsValidation = pricePointsController.validateDefaultPricePointSelection();
    if (!pricePointsValidation.valid) {
      missingFields.push({ id: "price-point-validation-message", name: pricePointsValidation.message.replace(/<\/?[^>]+(>|$)/g, "") });
    }

    if (missingFields.length > 0) {
      this.showModal(missingFields);
    } else {
      let form = this.element.querySelector('.camp-form')
      form.submit()
    }
  }

  showModal(missingFields) {
    this.modalTarget.style.visibility = "visible";
    let modalBody = this.modalTarget.querySelector(".modal-body");
    modalBody.innerHTML = "<p>Please fill in the following required fields:</p><ul>";

    missingFields.forEach((field) => {
      modalBody.innerHTML += `<li><a href="#${field.id}">${field.name}</a></li>`;
    });

    modalBody.innerHTML += "</ul>";

    let modal = new Modal(this.modalTarget);
    modal.show();
  }
}
