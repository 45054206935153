import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: true,
        lengthMenu: [
          [50, 100, 250],
          ['50 rows', '100 rows', '250 rows'],
        ],
        paging: true,
        pageLength: 100,
        ajax: {
          url: $(this.tableTarget).data('source'),
        },
        order: [[3, 'desc']],
        columns: [
          { data: 'last_name' },
          { data: 'first_name' },
          { data: 'age' },
          { data: 'Emergency Contact' },
          { data: 'Physician Contact' },
          { data: 'Medical Notes' },
          { data: 'Asthma' },
          { data: 'Diabetes' },
        ],
        dom: 'Blfrtip',
        buttons: this.createButtons(),
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: 'SEARCH',
        },
      });
    }
  }

  createButtons() {
    const filename = 'full-attendee-table-' + Date.now();

    return [
      {
        extend: 'copyHtml5',
        text: '<i class="fas fa-copy"></i>',
        titleAttr: 'Copy',
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
      {
        extend: 'print',
        text: '<i class="fas fa-print"></i> Print',
        titleAttr: 'Print',
        title: filename,
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
      {
        extend: 'csvHtml5',
        text: '<i class="fas fa-table"></i> CSV',
        titleAttr: 'CSV',
        title: filename,
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
    ];
  }
}

