import React, { useState } from "react";

const HeightField = ({ onChange }) => {
  const [feet, setFeet] = useState("1");
  const [inches, setInches] = useState("0");

  const handleFeetChange = (event) => {
    const newFeet = event.target.value;
    setFeet(newFeet);
    const height = `${newFeet}ft ${inches}in`;
    onChange(height);
  };

  const handleInchesChange = (event) => {
    const newInches = event.target.value;
    setInches(newInches);
    const height = `${feet}ft ${newInches}in`;
    onChange(height);
  };

  const feetOptions = Array.from({ length: 8 }, (_, i) => (
    <option value={i + 1} key={i + 1}>
      {`${i + 1} ft`}
    </option>
  ));

  const inchesOptions = Array.from({ length: 13 }, (_, i) => (
    <option value={i} key={i}>
      {`${i} in`}
    </option>
  ));

  return (
    <div className="height-select-container">
      <span className="error-span"></span>
      <label className="height">Height</label>
      <div className="camper-info-row">
        <select
          className="third-field"
          name="feet"
          onChange={handleFeetChange}
          value={feet}
        >
          {feetOptions}
        </select>

        <select
          className="third-field"
          name="inches"
          onChange={handleInchesChange}
          value={inches}
        >
          {inchesOptions}
        </select>
      </div>
    </div>
  );
};

export default HeightField;