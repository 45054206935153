import React, { useState, useEffect } from 'react';
import PricePointSelect from "./price_point_select.js";
import { Spinner } from 'spin.js';
import Toastify from "toastify-js";
import currency from "currency.js";


// Initialize Stripe and create a card element with custom styles 
// process.env.STRIPE_PUBLISHABLE
const stripe = Stripe(process.env.STRIPE_PUBLISHABLE);
const elements = stripe.elements();
const card = elements.create("card", {
  style: {
    base: {
      fontFamily: "Arial, sans-serif",
      fontSize: "30px",
      color: "#C1C7CD",
    },
    invalid: {
      color: "red",
    },
  },
});

// Add an event listener to the card element to handle errors
card.addEventListener("change", function (event) {
  let displayError = document.getElementById("card-errors");
  if (event.error) {
    displayError.textContent = event.error.message;
  } else {
    displayError.textContent = "";
  }
});

// LoadingPanel component for displaying a loading spinner
const LoadingPanel = ({ loading_payment }) => {
  if (!loading_payment) {
    return null;
  }

  return (
    <div className="loading-panel">
      <h3 className="lp-heading">Sending Payment information</h3>
      <div id="loading-spinner"></div>
      <br />
      <p className="lp-content">Please do not reload the page.</p>
    </div>
  );
};

const PaymentForm = ({
  step,
  buttonStyles,
  primary_price_point,
  price_points,
  price_cents,
  camper_identifier,
  fee_percentage,
  fee_breakout,
  campers,
  camp_id,
  csrfToken,
}) => {
  const [state, setState] = useState({
    current_camper: '',
    liability_waiver: false,
    shirt_size: '',
    custom_data: {},
    graduation_year: '',
    user_notes: '',
    liability_waiver_signature: '',
    liability_waiver_initials: '',
    disabled: false,
    discount_code: '',
    discount_amount: 0,
    discount_type: '',
    discounted: false,
    loading_payment: false,
    price_point_id: primary_price_point.id,
    alt_button_styles: {
      color: buttonStyles.backgroundColor,
      backgroundColor: buttonStyles.color,
      border: `2px solid ${buttonStyles.backgroundColor}`,
    },
  });

  useEffect(() => {
    card.mount('#card-element');
    setInitialState();
  }, []);

  const setInitialState = async () => {
    const currentPlayer = campers[camper_identifier];
    setPlayerAttributes(currentPlayer);
    await fetchCamper(currentPlayer, camper_identifier);

    const parsedPricePoints = typeof price_points === "string"
      ? JSON.parse(price_points)
      : price_points;
    
    const openPricePoints = parsedPricePoints.filter(
      (point) => point.registration_count < point.capacity
    );

    // Sort the price points based on some criteria, for example, by id
    openPricePoints.sort((a, b) => a.id - b.id);

    // Update the available options
    // setAvailablePricePoints(openPricePoints);

    // Set either primary or first available option as the default
    let defaultPricePoint = primary_price_point;

    if (primary_price_point && openPricePoints.find(pp => pp.id === primary_price_point.id)) {
      defaultPricePoint = primary_price_point;
    } else if (openPricePoints.length > 0) {
      defaultPricePoint = openPricePoints[0];
    }
    setCartFields(defaultPricePoint, false);
  }

  const setPlayerAttributes = (player) => {
    const shirtSize = player?.shirt_size || '';
    const customData = player?.custom_data || {};
    const gradYear = player?.graduation_year || '';
    const userNotes = player?.user_notes || '';
    const liabilityWaiverSignature = player?.liability_waiver_signature || '';
    const liabilityWaiverInitials = player?.liability_waiver_initials || '';
    setState((prevState) => ({ ...prevState, 
                              shirt_size: shirtSize,
                              custom_data: customData,
                              graduation_year: gradYear,
                              user_notes: userNotes,
                              liability_waiver_signature: liabilityWaiverSignature,
                              liability_waiver_initials: liabilityWaiverInitials }));
  }

  const fetchCamper = async (player, existingCamperId) => {
    const shouldUpdate = existingCamperId && existingCamperId.length > 10;
    const endpoint = shouldUpdate ? `/campers/${existingCamperId}` : "/campers";
    const method = shouldUpdate ? 'PATCH' : 'POST';  // Use 'PUT' if you're replacing the entire resource

    try {
      const response = await fetch(endpoint, {
        method: method,
        body: JSON.stringify({ camper: player }),
        headers: {
          'Content-Type': 'application/json',
          // Include other headers like authorization tokens if needed
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setState((prevState) => ({ ...prevState, current_camper: data.id, liability_waiver: true }));

    } catch (error) {
      console.error('Fetch request failed: ', error);
    }
  }

  useEffect(() => {
    if (validDiscountCode()) {
      let pricePoints = JSON.parse(price_points);
      let pricePoint = pricePoints.find((obj) => obj.id === state.price_point_id);
      setCartFields(pricePoint, true);
    }
  }, [state.discount_amount]);


  useEffect(() => {    
    const target = document.getElementById("loading-spinner");
    if (target != null) {
      const pinWheel = new Spinner({ color: "#fff", lines: 10, speed: 1 }).spin(
        target
      );
    }
  }, []);

  const monthName = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][monthNumberStartingFromZero].toString();
  };

  const currentMonthNumber = () => {
    const monthNumberStartingFromZero = new Date().getMonth();
    return [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ][monthNumberStartingFromZero].toString();
  };

  const validateDiscountCodeLength = (field) => {
    if (field.length > 5) {
      return true;
    }
  };

  const validDiscountCode = () => {
    return validateDiscountCodeLength(state.discount_code);
  };

const applyFee = (newSubTotalCents) => {
  let feeCents;

  if (fee_breakout) {
    feeCents = calculateFee(newSubTotalCents);
    let feeRow = document.getElementById("fee-row");
    let feeTotal = document.getElementById("fee");
    feeTotal.classList.add("price-value");
    feeTotal.innerText = feeCents.format();
  } else {
    feeCents = currency(0, { fromCents: true });
  }

  return feeCents;
};

const setCartFields = (pricePoint, discounted) => {
  let subTotalCents = currency(pricePoint.price_cents, { fromCents: true });
  let discountCents = currency(state.discount_amount, { fromCents: true });
  let amountOff = currency(0, { fromCents: true });

  // Define elements
  const subTotalField = document.getElementById("subtotal");
  const totalField = document.getElementById("total-value");

  if (discounted) {
    amountOff = applyDiscount(subTotalCents, discountCents);
  }

  subTotalField.classList.add("price-value");
  subTotalField.innerText = subTotalCents.format();

  let whateva = amountOff || currency(0, { fromCents: true });

  const newSubTotalCents = subTotalCents.subtract(whateva);

  const feeCents = applyFee(newSubTotalCents);

  const newTotalCents = subTotalCents.add(feeCents).subtract(amountOff) || currency(0, { fromCents: true });
  totalField.innerText = newTotalCents.format();
};

const applyDiscount = (subTotalCents, discountCents) => {
  // Define elements
  const discountRow = document.getElementById("discount-items");
  const discount = document.getElementById("discount");
  const discountTag = document.getElementById("discount-tag");
  const cancelDiscount = document.getElementById("remove-discount-code");

  // Add or remove classes
  discount.classList.add("price-value");
  discountRow.classList.remove("hidden");
  cancelDiscount.classList.remove("hidden");
  let amountOffTotal;

  if (state.discount_type === "percentage") {
    amountOffTotal = handlePercentageDiscount(subTotalCents, discountCents);
    console.log("%")
  } else {
    amountOffTotal = handleFlatDiscount(subTotalCents, discountCents);
    console.log("flat")
  }

  discount.innerText = "- " + amountOffTotal.format();
  return amountOffTotal;
};

const handlePercentageDiscount = (subTotalCents, discountCents) => {
  const percentOff = discountCents.divide(100);
  let amountOffCents;
  if (percentOff > 1) {
    amountOffCents = handleInvalidDiscount();
  } else {
    amountOffCents = subTotalCents.multiply(percentOff);
    notifyDiscountSuccess();
  }
  return amountOffCents;
};

const handleFlatDiscount = (subTotalCents, discountCents) => {
  let amountOffCents;

  if (discountCents.dollars() > subTotalCents.dollars()) {
    amountOffCents = handleInvalidDiscount();
  } else {
    amountOffCents = discountCents;
    notifyDiscountSuccess();
  }
  return amountOffCents;
};

const handleInvalidDiscount = () => {
  notifyToast("Discount Invalid - cannot apply discount for more than price", "discount-failure", "#dc3545");
  return currency(0, { fromCents: true });
};

const notifyDiscountSuccess = () => {
  document.getElementById("discount-tag").classList.remove("hidden");
  document.getElementById("discount-tag").innerText = state.discount_code;
  notifyToast("Discount Successful!", "discount-success", "#3ecf8e");
};

const notifyToast = (text, className, backgroundColor) => {
  Toastify({
    text: text,
    duration: 3000,
    close: false,
    position: "right",
    backgroundColor: backgroundColor,
    className: className,
    stopOnFocus: true,
  }).showToast();
};

const calculateFee = (newSubTotalCents) => {
  const firewoodFeeCents = newSubTotalCents.multiply(fee_percentage);
  const stripeFixedCents = currency(30, { fromCents: true });
  const updatedSubTotalCents = newSubTotalCents.add(firewoodFeeCents).add(stripeFixedCents);
  const newTotalWithStripeFee = updatedSubTotalCents.divide(0.971);
  return newTotalWithStripeFee.subtract(newSubTotalCents);
};


const pricePointDropdownChanged = (newId) => {
  const pricePoints = JSON.parse(price_points);
  const pricePoint = pricePoints.find((obj) => obj.id === newId);

  if (!pricePoint) {
    console.error(`No pricePoint found with id: ${newId}`);
    return;
  }

  setState(prevState => ({ ...prevState, price_point_id: newId }));

  setCartFields(pricePoint, state.discounted);
};

const handleSubmit = (e) => {
  e.preventDefault();
  createToken();
};

const handleChange = (e) => {
  const name = e.target.dataset.name;
  setState(prevState => ({ ...prevState, [name]: e.target.value }));
};

const createToken = () => {
  const stripeTokenHandler = (token) => {
    const form = document.getElementById("new_registration");
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "payment[stripeToken]");
    hiddenInput.setAttribute("value", token.id);
    form.appendChild(hiddenInput);
    form.submit();
    setState(prevState => ({ ...prevState, loading_payment: true, disabled: true }));
  };

  stripe.createToken(card).then((result) => {
    if (result.error) {
      const errorElement = document.getElementById("card-errors");
      errorElement.textContent = result.error.message;
      setState(prevState => ({ ...prevState, loading_payment: false, disabled: false }));
    } else {
      stripeTokenHandler(result.token);
    }
  });
};

const resetDiscountCode = () => {
  Toastify({
    text: "Discount Removed",
    duration: 3000,
    close: false,
    position: "right",
    backgroundColor: "#dc3545",
    className: "discount-failure",
    stopOnFocus: true,
  }).showToast();

  const discountRow = document.getElementById("discount-items");
  const discountTag = document.getElementById("discount-tag");
  const cancelDiscount = document.getElementById("remove-discount-code");

  cancelDiscount.classList.add("hidden");
  discountTag.classList.add("hidden");
  discountRow.classList.add("hidden");

  const pricePoints = JSON.parse(price_points);
  const pricePoint = pricePoints.find((obj) => obj.id === state.price_point_id);
  setCartFields(pricePoint, false);
  setState(prevState => ({ ...prevState, discounted: false, discount_code: "" }));
};

const handleDiscountCode = (e) => {
  const code = document.querySelector(".discount-box").value;
  if (!state.discounted) {
    const discountRow = document.getElementById("discount-items");
    const discount = document.getElementById("discount");
    fetch("/verify_discount", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ discount: { camp_id, code } }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          const { discount_type, amount_cents } = data.discount_data;
          setState(prevState => ({ ...prevState,
            discounted: true,
            discount_amount: amount_cents,
            discount_type,
          }));
          const pricePoints = JSON.parse(price_points);
          const pricePoint = pricePoints.find((obj) => obj.id == state.price_point_id);

          setCartFields(pricePoint, true);
        } else {
          setState(prevState => ({ ...prevState, discounted: false, discount_amount: 0, discount_type: null }));
          discount.classList.add("price-value");
          discountRow.classList.remove("hidden");
          const nonDiscountAmountOff = currency(0, { fromCents: true });
          discount.innerText = "- " + nonDiscountAmountOff.format();

          Toastify({
            text: "Discount Invalid",
            duration: 3000,
            newWindow: false,
            close: false,
            className: "discount-failure",
            gravity: "top",
            position: "right",
            backgroundColor: "#dc3545",
            stopOnFocus: true,
          }).showToast();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    }
  };
  let url;
  if (window.location.pathname.includes('/waitlist_invitations/')) {
    let id = window.location.pathname.split('/')[2]; // Extracts the id from the URL.
    url = `/waitlist_invitations/${id}/update`;
  } else {
    url = `/camps/${camp_id}/registrations`;
  }
  return (
        <form
          className={"new-step-form"}
          width={"100%"}
          id={"new_registration"}
          method={"POST"}
          action={`${url}`}
        >
          <div className={"choppa"}>
            <h1 className={"form-heading"}>{`Step ${step}. Payment`}</h1>
          </div>
          <div className={"purchase-recap price-point-options"}>
            <PricePointSelect
              price_points={price_points}
              primary_price_point={primary_price_point}
              onChange={pricePointDropdownChanged}
            />
            <div className={"payment-column"}>
              <input key={"utf8"} name={"utf8"} type={"hidden"} value={"✓"} />
              <input
                key={"authenticity_token"}
                name={"authenticity_token"}
                type={"hidden"}
                value={csrfToken}
              />
              <input
                key={"registration[camper_id]"}
                name={"registration[camper_id]"}
                type={"hidden"}
                value={state.current_camper}
              />
              <input
                key={"registration[price_point_id]"}
                name={"registration[price_point_id]"}
                type={"hidden"}
                value={state.price_point_id}
              />
              <input
                key={"registration[liability_waiver]"}
                name={"registration[liability_waiver]"}
                type={"hidden"}
                value={state.liability_waiver}
              />
              <input
                key={"registration[shirt_size]"}
                name={"registration[shirt_size]"}
                type={"hidden"}
                value={state.shirt_size}
              />
              <input
                key={"registration[graduation_year]"}
                name={"registration[graduation_year]"}
                type={"hidden"}
                value={state.graduation_year}
              />
              <input
                key={"registration[user_notes]"}
                name={"registration[user_notes]"}
                type={"hidden"}
                value={state.user_notes}
              />
              <input
                key={"registration[liability_waiver_signature]"}
                name={"registration[liability_waiver_signature]"}
                type={"hidden"}
                value={state.liability_waiver_signature}
              />
              <input
                key={"registration[liability_waiver_initials]"}
                name={"registration[liability_waiver_initials]"}
                type={"hidden"}
                value={state.liability_waiver_initials}
              />
              <input
                key={"registration[custom_data]"}
                name={"registration[custom_data]"}
                type={"hidden"}
                value={JSON.stringify(state.custom_data)}
              />
              <LoadingPanel loading_payment={state.loading_payment} />
            </div>
            <div className={"camper-info-row"}>
              <div id={"card-element"} className={"full-field"}></div>
              <div id={"card-errors"} role={"alert"}></div>
            </div>
            <div className={"camper-info-row"}>
              <img
                className={"visa-cc"}
                src={
                  "https://firewood-misc-storage.s3.amazonaws.com/visa.png"
                }
                alt={"we take visa"}
              />
              <img
                className={"master-cc"}
                src={
                  "https://firewood-misc-storage.s3.amazonaws.com/mastercard.png"
                }
                alt={"we take mastercard"}
              />
              <img
                className={"discover-cc"}
                src={
                  "https://firewood-misc-storage.s3.amazonaws.com/discover.png"
                }
                alt={"we take discover"}
              />
              <img
                className={"amex-cc"}
                src={
                  "https://firewood-misc-storage.s3.amazonaws.com/amex.png"
                }
                alt={"we take amex"}
              />
            </div>
            <div className={"discount-row"}>
              <button
                type={"button"}
                id={"remove-discount-code"}
                className={"remove-discount-code hidden"}
                onClick={resetDiscountCode}
              >
                {"Remove"}
              </button>
              <input
                className={"discount-box half-field"}
                type={"text"}
                name={"payment[discount_code]"}
                size={30}
                maxLength={30}
                value={state.discount_code || ""}
                placeholder={"Enter Discount Code..."}
                data-name={"discount_code"}
                onChange={handleChange}
              />
              <button
                type={"button"}
                className={"verify-discount-code"}
                style={state.alt_button_styles}
                disabled={state.discounted}
                onClick={handleDiscountCode}
              >
                {"Apply"}
              </button>
            </div>
            <div className={"camper-info-row"}>
              <button
                type={"submit"}
                className={"button button-primary create payment-button"}
                style={buttonStyles}
                disabled={state.disabled}
                onClick={handleSubmit}
              >
                {"Register"}
              </button>
            </div>
          </div>
        </form>
      );
}

export default PaymentForm;