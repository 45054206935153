import React from 'react';

const TextAreaInput = ({ label, name, value, placeholder, onChange, onBlur, required = false, error }) => {
  const hasError = Boolean(error);
  const ariaDescribedBy = `${name}-error`;

  return (
    <div style={{clear: 'both'}} className={`textarea-container ${name}-container`}>
      <h4>{label}</h4>
      <label htmlFor={name} className="textarea-label">
        {label} {required && <span aria-hidden="true">*</span>}
      </label>
      <textarea
        id={name}
        rows={5}
        className={`${name} full-field ${hasError ? 'error' : ''}`}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        aria-required={required}
        aria-invalid={hasError}
        aria-describedby={hasError ? ariaDescribedBy : undefined}
      ></textarea>
      {hasError && <span id={ariaDescribedBy} className="error-message">{error}</span>}
    </div>
  );
};

export default TextAreaInput;
