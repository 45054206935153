import React from 'react';

const AttendeeCard = ({ profile }) => {
  const [firstName, lastName, updatedAt] = profile;
  return (
    <div className="attendee-card">
      <h3>{`${firstName} ${lastName}`}</h3>
      <p>Last Updated: {new Date(updatedAt).toLocaleDateString()}</p>
    </div>
  );
};

export default AttendeeCard;
