import React, { useState, useEffect } from "react";

const ExciteMedicalForm = (props) => {
  const [state, setState] = useState({
    physician: "",
    physician_contact_number: "",
    insurer: "",
    insurance_policy_holder: "",
    group_number: "",
    medications: "",
    conditions: "",
    dietary_restrictions: "",
    physical_restrictions: "",
    allergies: "",
    diabetes: false,
    asthma: false,
    special_needs: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const valid = () => {
    return true
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChecked = (e) => {
    const { name, checked } = e.target;
    setState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    let newData = {
      allergies: state.allergies,
      medications: state.medications,
      physician: "Not collected",
      physician_contact_number: "Not collected",
      insurer: "Not collected",
      insurance_policy_holder: "Not collected",
      group_number: "Not collected",
      asthma: state.asthma,
      conditions: state.conditions,
      diabetes: state.diabetes,
      dietary_restrictions: state.dietary_restrictions,
      physical_restrictions: state.physical_restrictions,
      special_needs: state.special_needs,
    };
    props.saveValues(newData);
    return props.nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <div className={"place"}>
        <h1 className={"form-heading"}>
          {"Step " + `${props.stepLabel}.` + " Medical Information"}
        </h1>
        <div className={"health-container camper-info-row"}>
          <span className={"up-size"}>{"Health Overview:"}</span>
          <span className={"error-span"}></span>
          <div className={"camper-info-row"}>
            <textarea
              rows={5}
              className={"meds full-field"}
              placeholder={"Please list any Medications..."}
              name={"medications"}
              onChange={handleChange}
              value={state.medications}
            ></textarea>
            <textarea
              rows={5}
              className={"conds full-field"}
              placeholder={"Please list any Medical Conditions - etc...."}
              name={"conditions"}
              onChange={handleChange}
              value={state.conditions}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <textarea
              rows={5}
              className={"allergiess full-field"}
              placeholder={"Please list any Allergies...."}
              name={"allergies"}
              onChange={handleChange}
              value={state.allergies}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <textarea
              rows={5}
              className={"dietary-restrictions full-field"}
              placeholder={"Please list any Dietary Restrictions...."}
              name={"dietary_restrictions"}
              onChange={handleChange}
              value={state.dietary_restrictions}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <textarea
              rows={5}
              className={"dietary-restrictions full-field"}
              placeholder={"Please list any Phyiscal Restrictions...."}
              name={"physical_restrictions"}
              onChange={handleChange}
              value={state.physical_restrictions}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <div className={"check-split third-field"}>
              <span
                htmlFor={"special_needs"}
                className={"check-span up-size"}
                >
                {"Special Needs"}
              </span>
              <input
                type={"checkbox"}
                className={"special-needs"}
                name={"special_needs"}
                value={state.special_needs}
                onChange={handleChecked}
              />
            </div>
            <div className={"camper-info-row"}>
              <div className={"check-split third-field"}>
                <span htmlFor={"diabetes"} className={"check-span up-size"}>
                  {"Diabetes"}
                </span>
                <input
                  type={"checkbox"}
                  className={"diabetes"}
                  name={"diabetes"}
                  value={state.diabetes}
                  onChange={handleChecked}
                  />
              </div>
              <div className={"check-split third-field"}>
                <span htmlFor={"asthma"} className={"check-span up-size"}>
                  {"Asthma"}
                </span>
                <input
                  type={"checkbox"}
                  className={"asthma"}
                  name={"asthma"}
                  value={state.asthma}
                  onChange={handleChecked}
                />
              </div>
            </div>
            <div className={"camper-info-row"}>
              <button
                type={"button"}
                className={"button button-primary create"}
                disabled={!valid()}
                style={props.buttonStyles}
                onClick={saveAndContinue}
              >
                {"Next Step"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ExciteMedicalForm;
