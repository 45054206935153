import React, { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

const SignatureField = () => {
  const [trimmedDataUrl, setTrimmedDataUrl] = useState(null);
  const sigPad = useRef(null);

  const clear = () => {
    sigPad.current.clear();
  };

  const save = () => {
    console.log(sigPad.current.getTrimmedCanvas());
    setTrimmedDataUrl(sigPad.current.getTrimmedCanvas().toDataURL());
  };

  return (
    <div>
      <SignatureCanvas penColor="green" ref={sigPad} />
      <div>
        <button onClick={clear}>Clear</button>
        <button onClick={save}>Save</button>
      </div>
      {trimmedDataUrl && (
        <div>
          <img src={trimmedDataUrl} alt="Trimmed signature" />
        </div>
      )}
    </div>
  );
};

export default SignatureField;
