import { Controller } from "@hotwired/stimulus";
import 'selectize';

export default class extends Controller {
  static targets = ["discountCode", "fieldsContainer", "validationMessage", "destroyField"];

  connect() {
    this.initializeSelectize();
  }

  initializeSelectize() {
    this.discountCodeTargets.forEach((input) => {
      $(input).selectize({
        plugins: ['enter_key_delim'],
        sortField: 'code',
        maxItems: 1,
        create: function(input, callback) {
          callback({
            value: input,
            text: input
          });
        }
      });
    });
  }

  addFields(event) {
    event.preventDefault();
    const time = new Date().getTime();
    const addButton = event.currentTarget;
    const regexp = new RegExp(addButton.dataset.id, 'g');
    const template = addButton.dataset.fields.replace(regexp, time);
    addButton.insertAdjacentHTML('beforebegin', template);
    this.initializeSelectize();
  }

  removeFields(event) {
    event.preventDefault();
    const button = event.currentTarget;
    // Debug statement to ensure the event and button are correctly captured
    console.log("Button clicked:", button);
    const fieldset = button.closest("fieldset.discount-code");
    // Debug statement to check if the fieldset is correctly found
    console.log("Fieldset found:", fieldset);
    if (!fieldset) {
      console.error("No fieldset with class 'discount-code' found.");
      return;
    }
    const markToDelete = fieldset.querySelector("input.destroy-field");
    // Debug statement to check if the markToDelete input is correctly found
    console.log("Destroy field input found:", markToDelete);
    if (!markToDelete) {
      console.error("No input.destroy-field found within the fieldset.");
      return;
    }
    const fields = fieldset.querySelectorAll("input");
    // Debug statement to check all inputs within the fieldset
    console.log("All input fields within the fieldset:", fields);
    fields.forEach(field => field.removeAttribute('required'));
    // Debug statement before setting the value
    console.log("Setting _destroy to '1' for:", markToDelete);
    markToDelete.value = "1";  // Set _destroy to "1" to mark for deletion
    // Debug statement before hiding the fieldset
    console.log("Hiding fieldset:", fieldset);
    fieldset.style.display = "none";
  }

}