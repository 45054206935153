import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    if (!this.hasTableTarget) return;

    $(this.tableTarget).DataTable({
      responsive: true,
      retrieve: true,
      autoWidth: false,
      processing: true,
      serverSide: true,
      paging: true,
      pageLength: 100,
      lengthMenu: [
        [50, 100, 250, 500],
        ["50 rows", "100 rows", "250 rows", "500 rows"],
      ],
      ajax: {
        url: $(this.tableTarget).data("source"),
      },
      columns: [
        { data: "User Status" },
        { data: "Browser" },
        { data: "Device Type" },
        { data: "OS" },
        { data: "Landing Page" },
        { data: "UTM Medium" },
        { data: "UTM Campaign" },
        { data: "Referring Domain" },
        { data: "Estimated LTV" },
      ],
      dom: "Blfrtip",
      buttons: [
        {
          text: "Export All",
          action: function (e, dt, node, config) {
            window.location.href = "/admin/analytics/export_all.csv";
          },
        },
        {
          extend: "copyHtml5",
          text: '<i class="fas fa-copy"></i> Copy',
          titleAttr: "Copy",
        },
        {
          extend: "print",
          text: '<i class="fas fa-print"></i> Print',
          titleAttr: "Print",
          title: "website-visits-table-" + Date.now(),
          exportOptions: {
            modifier: {
              selected: null,
            },
          },
        },
        {
          extend: "csvHtml5",
          text: '<i class="fas fa-table"></i> CSV',
          titleAttr: "CSV",
          title: "website-visits-table-" + Date.now(),
          exportOptions: {
            modifier: {
              selected: null,
            },
          },
        },
      ],
      language: {
        search: '<i class="fa fa-search"></i>',
        searchPlaceholder: "SEARCH",
      },
    });
  }
}

