import React, { useState, useCallback } from "react";

const WeightSelectField = ({ onChange }) => {
  const [weight, setWeight] = useState("");

  const handleChange = useCallback(
    (event) => {
      setWeight(event.target.value, () => {
        onChange(weight);
      });
    },
    [onChange, weight]
  );

  const generateWeightOptions = () => {
    const weightOptions = [];
    for (let i = 25; i <= 450; i += 5) {
      weightOptions.push(
        <option key={i} value={i}>
          {i} lbs
        </option>
      );
    }
    return weightOptions;
  };

  return (
    <div className="weight-select-container">
      <span className="error-span"></span>
      <label className="weight">Weight</label>
      <select
        className="field"
        name="weight"
        value={weight}
        onChange={handleChange}
      >
        <option value="" disabled>
          Select weight (lbs)
        </option>
        {generateWeightOptions()}
      </select>
    </div>
  );
};

export default WeightSelectField;
