import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="menu"
export default class extends Controller {
  static targets = [ "menuList", "dashboardLink" ]

  connect() {
    window.addEventListener('resize', this.checkScreenWidth);
  }

  disconnect() {
    window.removeEventListener('resize', this.checkScreenWidth);
  }

  checkScreenWidth(){
    const menuList = document.querySelector("#menu-list")
    if (window.innerWidth > 1100) {
      menuList.style.display = 'block'
    } else {
      menuList.style.display = 'none'
    }
  }

  toggleMenu() {
    this.menuListTarget.style.display = (this.menuListTarget.style.display == '' || this.menuListTarget.style.display == 'none') ? 'block' : 'none';

     this.dashboardLinkTargets.forEach((dashboardLink) => {
        if (dashboardLink.style.display == '' || dashboardLink.style.display == 'none') {
          dashboardLink.style.display = 'block'
        }
     })
  }
}

