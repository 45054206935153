import React, { useState } from 'react';

const InsuranceGroupNumberInput = () => {
  const [groupNumber, setGroupNumber] = useState('');
  const [error, setError] = useState('');

  const validateGroupNumber = (number) => {
    // Reset error message
    setError('');

    // Check if the field is empty
    if (!number) {
      setError('Insurance group number is required.');
      return false;
    }

    // Check length: more than 5 characters but no more than 25
    if (number.length <= 5 || number.length > 25) {
      setError('Insurance group number should be more than 5 characters and less than 25 characters long.');
      return false;
    }

    // Check format (alphanumeric characters only)
    if (!/^[a-zA-Z0-9]+$/.test(number)) {
      setError('Insurance group number should contain only letters and numbers.');
      return false;
    }

    return true; // Valid
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateGroupNumber(groupNumber)) {
      console.log('Insurance group number is valid:', groupNumber);
      // Process the valid input as necessary
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor={"groupNumber"}>Insurance Group Number:</label>
        <input
          type="text"
          id="groupNumber"
          value={groupNumber}
          onChange={(e) => setGroupNumber(e.target.value)}
          placeholder="Enter insurance group number"
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
      <button type="submit">Submit</button>
    </form>
  );
};

export default InsuranceGroupNumberInput;
