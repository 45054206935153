import React from 'react';

const PhysicianInfoForm = ({ state, handleChange, checkInput }) => {
  return (
    <div className={"camper-info-form"}>
      <label className={"up-size big-span"}>
        {"Physician Information: *"}
      </label>
      <span className={"error-span"}></span>
      <input
        type={"text"}
        className={"phys full-field"}
        placeholder={"Physician Name *"}
        data-error-message={
          "Physician Name must be more than the specified number of characters."
        }
        data-min-length={"3"}
        name={"physician"}
        value={state.physician}
        onChange={handleChange}
        onBlur={checkInput}
      />
    </div>
  );
};

export default PhysicianInfoForm;