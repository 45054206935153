import React, { useState, useEffect } from 'react';
import AttendeeProfileCard from './attendee_profile_card';

const AttendeeProfileList = ({ onExistingAttendeeProfileSelect, profilesJson }) => {
  const [visibleCount, setVisibleCount] = useState(3);
  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    if (profilesJson) {
      try {
        const parsedProfiles = JSON.parse(profilesJson);
        if (Array.isArray(parsedProfiles)) {
          setProfiles(parsedProfiles);
        }
      } catch (error) {
        console.error('Error parsing profiles JSON:', error);
        // Handle the error appropriately
      }
    }
  }, [profilesJson]);

  const sortedProfiles = profiles
    .slice()
    .sort((a, b) => new Date(b[2]) - new Date(a[2]));

  const showMore = () => {
    setVisibleCount(prevCount => prevCount + 3);
  }

  const handleCardClick = (profile) => {
    let id = profile
    onExistingAttendeeProfileSelect(profile); // This is the callback passed from the parent
  };

  return (
    <div className="camper-info-row">
      {sortedProfiles.slice(0, visibleCount).map((profile, index) => (
        <AttendeeProfileCard
          key={index}
          profile={profile}
          onClick={() => handleCardClick(profile)}
        />
      ))}
      {visibleCount < sortedProfiles.length && (
        <div className="camper-info-row">
          <button type="button" onClick={showMore}>Show More</button>
        </div>
      )}
    </div>
  );
};

export default AttendeeProfileList;
