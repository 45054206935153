import { useState, useEffect } from "react";

const useRegistrationFlowHelpers = (props) => {

  const [pricePoints, setPricePoints] = useState(props.price_points);

  const createFullPricePointPositionArray = () => {
    let pricePointPositions = [];
    for (let i = 0; i < pricePoints.length; i++) {
      if (pricePoints[i].registration_count >= pricePoints[i].capacity) {
        pricePointPositions.push(pricePoints[i]);
      }
    }
    return pricePointPositions;
  };
  const setFullPricePointFlag = () => {
    for (const pricePoint of pricePoints) {
      if (pricePoint.registration_count >= pricePoint.capacity) {
        return true;
      }
    }
    return false;
  };

  const createOpenPricePointArray = () => {
    return pricePoints.filter(
      (pricePoint) => pricePoint.registration_count <= pricePoint.capacity
    );
  };

  const createFullPricePointArray = () => {
    return pricePoints.filter(
      (pricePoint) => pricePoint.registration_count >= pricePoint.capacity
    );
  };

  const campFull = () =>
    pricePoints.length === createFullPricePointArray(pricePoints).length;

  return {
    campFull,
    setFullPricePointFlag,
    createFullPricePointArray,
    createFullPricePointPositionArray,
    createOpenPricePointArray,
  };
};

// Export the custom hook
export default useRegistrationFlowHelpers;
