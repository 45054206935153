import { Controller } from "@hotwired/stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static targets = ["form", "email", "phone", "emailError", "phoneError", "modal", "modalBody"]

  connect() {
    this.emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    this.phoneRegExp = /^\d{3}-\d{3}-\d{4}$/
  }

  validate(event) {
    event.preventDefault()


    const emailValid = this.validateEmail()
    const phoneValid = this.validatePhone()

    if (emailValid && phoneValid) {
      console.log("Form is valid, submitting...")
      this.formTarget.submit() // Use requestSubmit() to submit the form programmatically
    } else {
      console.log("Form validation failed")
      const missingFields = [
        ...(emailValid ? [] : [{ id: "email-error", name: "Email" }]),
        ...(phoneValid ? [] : [{ id: "phone-error", name: "Phone" }])
      ]
      this.showModal(missingFields)
    }
  }

  validateEmail() {
    const email = this.emailTarget.value
    console.log("Validating email:", email)
    if (this.emailRegExp.test(email)) {
      this.clearError(this.emailErrorTarget)
      return true
    } else {
      this.showError(this.emailErrorTarget, "Please enter a valid email address.")
      return false
    }
  }

  validatePhone() {
    const phone = this.phoneTarget.value
    console.log("Validating phone:", phone)
    if (this.phoneRegExp.test(phone)) {
      this.clearError(this.phoneErrorTarget)
      return true
    } else {
      this.showError(this.phoneErrorTarget, "Please enter a valid phone number.")
      return false
    }
  }

  showError(target, message) {
    console.log("Showing error:", message)
    target.textContent = message
    target.classList.remove("hidden")
  }

  clearError(target) {
    console.log("Clearing error")
    target.textContent = ""
    target.classList.add("hidden")
  }

  showModal(missingFields) {
    this.modalTarget.style.visibility = "visible"
    this.modalBodyTarget.innerHTML = "<p>Please fill in the following required fields:</p><ul>"

    missingFields.forEach((field) => {
      this.modalBodyTarget.innerHTML += `<li><a href="#${field.id}">${field.name}</a></li>`
    })

    this.modalBodyTarget.innerHTML += "</ul>"

    const modal = new Modal(this.modalTarget)
    modal.show()
  }
}
