import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: true,
        paging: true,
        pageLength: 50,
        lengthMenu: [
          [25, 50, 100],
          ['25 rows', '50 rows', '100 rows'],
        ],
        ajax: {
          url: $(this.tableTarget).data('source'),
        },
        columns: [
          { data: 'Registration ID' },
          { data: 'Attendee Last Name' },
          { data: 'Attendee First Name' },
          { data: 'User Email' },
          { data: 'Event' },
          { data: 'Total Refunded' },
        ],
        dom: 'Bfrtip',
        buttons: [
          {
            extend: 'copyHtml5',
            text: '<i class="fas fa-copy"></i> Copy',
            titleAttr: 'Copy',
          },
          {
            extend: 'print',
            text: '<i class="fas fa-print"></i> Print',
            titleAttr: 'Print',
            title: 'camper-list-table' + Date.now(),
          },
          {
            extend: 'csvHtml5',
            text: '<i class="fas fa-table"></i> CSV',
            titleAttr: 'CSV',
            title: 'camper-list-table' + Date.now(),
          },
        ],
      });
    }
  }
}
