import React, { useState, useEffect, useCallback } from "react";
import Cleave from "cleave.js/dist/cleave-react";

const WeightField = ({ onChange }) => {
  const [weight, setWeight] = useState("");

  const handleChange = useCallback(
    (event) => {
      setWeight(event.target.value);
    },
    []
  );
  
  useEffect(() => {
    onChange(weight);
  }, [weight]);

  return (
    <div className="weight-select-container">
      <span className="error-span"></span>
      <label className="weight">Weight</label>
      <Cleave
        id="new-weight-cleave"
        className={"field"}
        type={"text"}
        maxLength="3"
        placeholder={"Weight (lbs)"}
        value={weight}
        name={"weight"}
        options={{ numericOnly: true }}
        onChange={handleChange}
      />
    </div>
  );
};
export default WeightField;
