import * as React from "react";
import * as ReactDom from "react-dom";

export default class GuardianField extends React.Component {
  static displayName = "GuardianField";

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"camper-info-row"}>
        <span className={"error-span"}></span>
        <label className={"big-span"}>
          {"Guardian Contact Information: *"}
        </label>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Guardian Name"}
          data-error-message={
            "Guardian Contact Name must be longer than the specified number of characters."
          }
          data-min-length={"5"}
          name={"guardian_contact"}
          ref={"guardian_contact"}
          minLength={5}
          defaultValue={this.props.emergency_contact}
          onChange={this.handleChange}
          onBlur={this.checkInput}
        />
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Guardian Relationship - Mother, Father, etc"}
          data-error-message={
            "Guardian Relationship must be longer than the specified number of characters."
          }
          data-min-length={"5"}
          name={"guardian_contact_relationship"}
          ref={"guardian_contact_relationship"}
          minLength={2}
          onChange={this.handleChange}
          onBlur={this.checkInput}
        />
        <div className={"half"}>
          <CountryCodeDropdown
            value={this.state.guardian_contact_country_code}
            onChange={this.guardianCountryCodeDropdownChanged}
          />
          <Cleave
            type={"tel"}
            id={""}
            className={"ecn phone-us field two-thirds-field"}
            size={10}
            pattern={"[0-9]{10}"}
            id={""}
            name={"guardian_contact_number"}
            ref={"guardian_contact_number"}
            placeholder={"Guardian Contact #"}
            data-error-message={
              "Guardian Phone must be 10 characters. If you are still having issues, clear the field and try again."
            }
            data-min-length={"10"}
            options={{ blocks: [3, 3, 4], delimiter: "-" }}
            onChange={this.handlePhoneChange}
            onBlur={this.checkInput}
          />
        </div>
        <input
          type={"email"}
          className={"half full-field"}
          placeholder={"Guardian Email"}
          data-error-message={
            "Guardian Relationship must be longer than the specified number of characters."
          }
          data-min-length={"5"}
          name={"guardian_contact_email"}
          ref={"guardian_contact_email"}
          minLength={5}
          required={true}
          onChange={this.handleChange}
          onBlur={this.checkInput}
        />
      </div>
    );
  }
}
