import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("submit", this.validateForm.bind(this));
  }

  validateForm(event) {
    const requiredCheckboxes = this.element.querySelectorAll('input[type="checkbox"][required]');
    for (let checkbox of requiredCheckboxes) {
      if (!checkbox.checked) {
        event.preventDefault();
        alert("Please ensure all required fields are checked. Unrequire unchecked fields if needed.");
        return;
      }
    }
  }
}
