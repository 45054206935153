import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['table'];

  connect() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    if (this.hasTableTarget) {
      $(this.tableTarget).DataTable({
        responsive: true,
        retrieve: true,
        autoWidth: false,
        processing: true,
        serverSide: true,
        order: [[6, 'desc']],
        paging: true,
        pageLength: 100,
        ajax: {
          url: $(this.tableTarget).data('source'),
        },
        columns: [
          { data: 'Active' },
          { data: 'Attendee' },
          { data: 'Event', className: "text-align-left" },
          { data: 'Amount Paid', className: "text-align-left amount-paid" },
          { data: 'Registering User' },
          { data: 'Transaction Info', className: "text-align-left" },
          { data: 'Signed Up At' },
        ],
        dom: 'Blfrtip',
        buttons: this.createButtons(),
        language: {
          search: '<i class="fa fa-search"></i>',
          searchPlaceholder: 'SEARCH',
        },
      });
    }
  }

  createButtons() {
    const filename = 'registrations-table-' + Date.now();

    return [
      {
        extend: 'copyHtml5',
        text: '<i class="fas fa-copy"></i>',
        titleAttr: 'Copy',
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
      {
        extend: 'print',
        text: '<i class="fas fa-print"></i> Print',
        titleAttr: 'Print',
        title: filename,
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
      {
        extend: 'csvHtml5',
        text: '<i class="fas fa-table"></i> CSV',
        titleAttr: 'CSV',
        title: filename,
        exportOptions: {
          modifier: {
            selected: null,
          },
        },
      },
    ];
  }
}

