import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-responsive';

$.fn.dataTable.ext.type.order['custom-index-pre'] = function (data) {
  return parseInt(data, 10) || 9999;
};

export default class extends Controller {
  static targets = ["table", "collectCheckbox"];

  connect() {
    if (this.hasTableTarget) {
      this.initializeDataTable();
      this.initializeSortable();
      this.addCollectCheckboxListeners();
      this.reorderRows();
    }
  }

  initializeDataTable() {
    this.dataTable = $(this.tableTarget).DataTable({
      responsive: true,
      retrieve: true,
      autoWidth: false,
      processing: true,
      order: [[2, "asc"]],
      columnDefs: [
        { type: 'custom-index', targets: 2 } // Apply custom sorting to the third column (index column)
      ],
      lengthMenu: [
        [-1],
        ["All"],
      ],
      language: {
        search: '<i class="fa fa-search"></i>',
        searchPlaceholder: "SEARCH",
      },
    });
  }

  initializeSortable() {
    this.sortable = Sortable.create(this.tableTarget.querySelector("tbody"), {
      handle: '.sortable-handle',
      animation: 150,
      onEnd: this.updateIndexes.bind(this)
    });
  }

  addCollectCheckboxListeners() {
    this.collectCheckboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.reorderRows.bind(this));
    });
  }

  reorderRows() {
    const tbody = this.tableTarget.querySelector("tbody");
    const rows = Array.from(tbody.querySelectorAll("tr"));

    rows.sort((a, b) => {
      const aCollected = a.querySelector('input[name*="collect"]').checked;
      const bCollected = b.querySelector('input[name*="collect"]').checked;
      const aIndex = parseInt(a.querySelector('.index-input').value, 10);
      const bIndex = parseInt(b.querySelector('.index-input').value, 10);

      if (aCollected && !bCollected) return -1;
      if (!aCollected && bCollected) return 1;
      return aIndex - bIndex; // Change to bIndex - aIndex for descending order
    });

    rows.forEach(row => tbody.appendChild(row));
  }

  updateIndexes() {
    const rows = this.tableTarget.querySelectorAll("tbody tr.nested-fields");
    rows.forEach((row, index) => {
      const indexInput = row.querySelector(".index-input");
      if (indexInput) {
        indexInput.value = index;
      }
    });
  }
}
