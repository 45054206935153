import React, { useState, setState } from 'react';
import StateDropdown from "./state_dropdown.js";
import CountryCodeDropdown from "./country_code_dropdown.js";
import Cleave from 'cleave.js/react'; // For phone number formatting
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us';

const UtepGrassVballForm = ({
  collect_guardian_info,
  price_points,
  category,
  saveValues,
  nextStep,
  buttonStyles,
}) => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: "",
    address: "",
    city: "",
    state: "AL - Alabama",
    zip: "",
    teamName: '',
    teammates: [{ name: '' }],
    phone_number: '',
    contact_country_code: '',
  });

  const valid = () => {
    // Corrected property names to match state (e.g., `formData.first_name` to `formData.firstName`)
    if (
      validateLength(formData.first_name, 1) &&
      validateLength(formData.last_name, 1) &&
      validateLength(formData.address, 4) &&
      formData.city &&
      validateLength(formData.zip, 2)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CountryCodeDropdownChanged = (newCountryCode) => {
     setFormData({
       ...formData,
       contact_country_code: newCountryCode,
     });
   };


  const validateLength = (field, requiredLength) => {
    if (field && field.length > requiredLength) {
      return true;
    }
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target; // Also directly destructuring value here for use later

    // Adjusted to use 'setFormData' directly and corrected reference to 'contact_country_code'
    setFormData((prevState) => {
      // Determining if the input name matches certain fields and pre-pending country code if it does
      const isPhoneNumberField = name === "guardian_contact_number" || name === "emergency_contact_number" || name === "phone_number";
      const newValue = isPhoneNumberField ? `${prevState.contact_country_code} ${value}` : value;

      return {
        ...prevState,
        [name]: newValue,
      };
    });
  };

  const stateDropdownChanged = (newState) => {
    setFormData((prevState) => ({ ...prevState, state: newState }));
  };

  const validateEmail = (email) => {
    var emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      var errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  const checkInput = (e) => {
    let itemClass = e.target.className;
    let item = e.target;
    let val = e.target.value;
    let errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleTeammateChange = (index, event) => {
    const newTeammates = [...formData.teammates];
    newTeammates[index].name = event.target.value;
    setFormData((prevState) => ({
      ...prevState,
      teammates: newTeammates,
    }));
  };

  const handleAddTeammate = () => {
    setFormData((prevState) => ({
      ...prevState,
      teammates: [...prevState.teammates, { name: '' }],
    }));
  };

  const handleRemoveTeammate = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      teammates: prevState.teammates.filter((_, i) => i !== index),
    }));
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      custom_data: {
        teamName: formData.teamName,
        teammates: formData.teammates,
        phone_number: formData.phone_number,
      },
    };
    saveValues(data);
    return nextStep();
  };

  return (
    <form className="new-step-form vball-form">
      <div className={"buck-test"}>
        <h1 className={"form-heading"}>{"Step 1. Registration Information"}</h1>
      </div>
      <div className={"camper-info-row"}>
        <label className={"big-span"}></label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"First Name *"}
          data-error-message={"First Name must be at least two characters"}
          data-min-length={"2"}
          name={"first_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
        <input
          type={"text"}
          className={"full-field"}
          placeholder={"Last Name *"}
          data-error-message={"Last Name must be at least two characters"}
          data-min-length={"2"}
          name={"last_name"}
          minLength={2}
          required={true}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>

      <div className={"half"}>
        <CountryCodeDropdown
          value={formData.contact_country_code}
          onChange={CountryCodeDropdownChanged}

        />
        <Cleave
          type={"tel"}
          id={""}
          className={"ecn phone-us field two-thirds-field"}
          size={10}
          pattern={"[0-9]{10}"}
          id={""}
          name={"phone_number"}
          placeholder={"Primary Phone Number"}
          data-error-message={
            "Phone must be 10 characters. If you are still having issues, clear the field and try again."
          }
          data-min-length={"10"}
          options={{ blocks: [3, 3, 4], delimiter: "-", numericOnly: true }}
          onChange={handlePhoneChange}
          onBlur={checkInput}
        />
      </div>
        <div className={"camper-info-row address"}>
        <label>{"Primary Address: *"}</label>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"address full-field"}
          placeholder={"Street Address"}
          data-error-message={"Please enter a valid address"}
          data-min-length={"4"}
          name={"address"}
          minLength={4}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row address"}>
        <span className={"error-span"}></span>
        <input
          type={"text"}
          className={"city full-field"}
          placeholder={"City"}
          data-error-message={
            "City must be longer than the specified number of characters."
          }
          data-min-length={"4"}
          name={"city"}
          minLength={4}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="camper-info-row">
        <div className="error-span"></div>
        <StateDropdown value={formData.state} onChange={stateDropdownChanged} />
        <input
          type={"text"}
          className={"zip field third-field"}
          placeholder={"Zip Code (i.e. 20008)"}
          data-error-message={"Please enter a valid zip code."}
          data-min-length={"2"}
          name={"zip"}
          minLength={2}
          maxLength={10}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className="form-group">
        <label htmlFor="teamName">Team Name:</label>
        <input
          type="text"
          name="teamName"
          id="teamName"
          value={formData.teamName}
          onChange={handleChange}
          placeholder="Team Name"
          className="form-control"
        />
      </div>
      <div className="teammates-section">
        {formData.teammates.map((teammate, index) => (
          <div key={index} className="teammate-group">
            <label htmlFor={`teammate-${index}`}>{`Teammate ${index + 1}:`}</label>
            <input
              type="text"
              name={`teammate-${index}`}
              id={`teammate-${index}`}
              value={teammate.name}
              placeholder={`Teammate ${index + 1} Name`}
              onChange={(event) => handleTeammateChange(index, event)}
              required={index === 0} // Assuming the first teammate is required
              aria-label={`Teammate ${index + 1} Name`}
              className="form-control"
            />
            {index > 0 && (
              <button type="button" onClick={() => handleRemoveTeammate(index)} style={{marginTop: "15px", marginBottom: "15px"}} className="btn-remove">
                <i className="fa fa-minus-circle" aria-hidden="true"></i>  Remove
              </button>
            )}
          </div>
        ))}
        {formData.teammates.length < 8 && (
          <button type="button" onClick={handleAddTeammate} style={{marginTop: "15px", marginBottom: "15px"}} className="btn-add">
             <i className="fa fa-plus-circle" aria-hidden="true"></i> Add Teammate
          </button>
        )}
      </div>
      <button
        type={"submit"}
        className={"button button-primary create"}
        style={{ ...buttonStyles, marginTop: "50px" }}
        disabled={!valid()}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
};

export default UtepGrassVballForm;
