import React, { useEffect, useRef } from "react";

const AddressAutocompleteFields = ({ apiKey, label, value, onChange }) => {
  const inputRef = useRef();

  useEffect(() => {
    const handlePlaceChanged = () => {
      const place = autocomplete.getPlace();
      const streetNumber = place.address_components.find((component) =>
        component.types.includes("street_number")
      );
      const street = place.address_components.find((component) =>
        component.types.includes("route")
      );
      const city = place.address_components.find((component) =>
        component.types.includes("locality")
      );
      const state = place.address_components.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const zipCode = place.address_components.find((component) =>
        component.types.includes("postal_code")
      );

      onChange({
        target: {
          name: "address",
          value: {
            street: streetNumber
              ? `${streetNumber.long_name} ${street.long_name}`
              : street.long_name,
            city: city.long_name,
            state: state.short_name,
            zipCode: zipCode.long_name,
          },
        },
      });
    };

    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    let autocomplete;
    script.onload = () => {
      autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: "us" },
        }
      );

      autocomplete.addListener("place_changed", handlePlaceChanged);
    };

    return () => {
      document.body.removeChild(script);
      autocomplete &&
        autocomplete.removeListener("place_changed", handlePlaceChanged);
    };
  }, []);

  return (
    <div>
      <label>{label}</label>
      <input type="text" ref={inputRef} value={value} readOnly />
    </div>
  );
};

export default AddressAutocompleteFields;
