import React from 'react';

const AgeGroupSelect = ({ name, label, value, onChange }) => {
  const ageGroups = [
    'U4', 'U5', 'U6', 'U7', 'U8', 'U9', 'U10',
    'U11', 'U12', 'U13', 'U14', 'U15', 'U16', 'U17', 'U18', 'U19', 'U20'
  ];

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <select id={name} name={name} value={value} onChange={onChange}>
        <option value="">Select an age group</option>
        {ageGroups.map((ageGroup) => (
          <option key={ageGroup} value={ageGroup}>
            {ageGroup}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AgeGroupSelect;
