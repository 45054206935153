import React, { useState, useEffect } from "react";

// Define a function that returns the name of the month for a given month number
const monthName = (monthNumberStartingFromZero) =>
  [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][monthNumberStartingFromZero];

// Define the BirthDateDropdown component, which takes a birth_date prop and an onChange prop
const BirthDateDropdown = ({ birth_date, onChange }) => {
  const [error, setError] = useState('');

  const validateDate = (newDate) => {
    // Implement validation logic
    if (isInvalidDate(newDate)) {
      setError('Invalid date selected.');
    } else {
      setError('');
      onChange(newDate);
    }
  };

  // Create an array of day options, from 1 to 31
  const dayOptions = Array.from({ length: 31 }, (_, i) => i + 1);

  // Create an array of month options, using the monthName function
  const monthOptions = Array.from({ length: 12 }, (_, i) => {
    return {
      value: i,
      label: monthName(i),
    };
  });

  // Create an array of year options, from 1940 to the current year
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from({ length: currentYear - 1940 + 1 }, (_, i) => {
    const year = currentYear - i;
    return {
      value: year,
      label: year,
    };
  });

  // Define the onChange functions for each select element
  const handleYearChange = (event) => {
    const newDate = new Date(
      event.target.value,
      birth_date.getMonth(),
      birth_date.getDate()
    );
    onChange(newDate);
  };

  const handleMonthChange = (event) => {
    const newDate = new Date(
      birth_date.getFullYear(),
      event.target.value,
      birth_date.getDate()
    );
    onChange(newDate);
  };

  const handleDayChange = (event) => {
    const newDate = new Date(
      birth_date.getFullYear(),
      birth_date.getMonth(),
      event.target.value
    );
    onChange(newDate);
  };

  return (
    <div className="birth-date-container">
      <div className="birth-date">
        <span className="bd-text">Birthdate</span>
      </div>
      <br />
      <div className="birth-date-half">
        <select
          className="month-select third-field"
          aria-label="Select Month" 
          value={birth_date.getMonth()}
          onChange={handleMonthChange}
        >
          {monthOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
      <div className="birth-date-quarter">
        <select
          className="third-field"
          aria-label="Select Day" 
          value={birth_date.getDate()}
          onChange={handleDayChange}
        >
          {dayOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className="birth-date-quarter">
        <select
          className="year-select third-field"
          value={birth_date.getFullYear()}
          aria-label="Select Year" 
          onChange={handleYearChange}
        >
          {yearOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default BirthDateDropdown;
