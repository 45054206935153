import React from "react";

const GuardianInfo = ({ name, label, value, onChange }) => {
  const handleNameChange = (e) => {
    onChange({ target: { name, value: { ...value, name: e.target.value } } });
  };

  const handleEmailChange = (e) => {
    onChange({ target: { name, value: { ...value, email: e.target.value } } });
  };

  return (
    <div>
      <label>{label}</label>
      <div>
        <label>Guardian Name</label>
        <input
          type="text"
          value={value.name || ""}
          onChange={handleNameChange}
        />
      </div>
      <div>
        <label>Guardian Email</label>
        <EmailInput
          name={`${name}_email`}
          label=""
          value={value.email || ""}
          onChange={handleEmailChange}
        />
      </div>
    </div>
  );
};

export default GuardianInfo;
