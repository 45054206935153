import React from 'react';

const GradePointAverageField = ({ name, label, value, onChange, step = 0.1, allowAboveFour = true }) => {
  const gpaOptions = [];
  for (let gpa = 0; gpa <= 4; gpa += step) {
    gpaOptions.push(gpa.toFixed(1));
  }

  if (allowAboveFour) {
    gpaOptions.push("4+");
  }

  return (
    <div className="gpa-input-container">
      <label htmlFor={name}>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        <option value="">Select GPA</option>
        {gpaOptions.map((gpa) => (
          <option key={gpa} value={gpa}>
            {gpa}
          </option>
        ))}
      </select>
    </div>
  );
};

export default GradePointAverageField;
