import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";
import "slim-select/dist/slimselect.css";

export default class extends Controller {
  static values = { url: String };

  connect() {
    let _this = this;
    new SlimSelect({
      select: this.element,
      addable: function (value) {
        let url = new URL(_this.urlValue);
        fetch(url, {
              headers: { "Content-Type": "application/json; charset=utf-8" },
              method: 'POST',
              body: JSON.stringify({ heading: value })
              }).then((response) => response.json())
                .then((json) => { return { text: json.heading, value: json.id } })
                .catch((error) => {return false})
       },
      searchingText: "Searching...",
      ajax: function (search, callback) {
        if (search.length < 2) {
          callback("Needs 2+ Characters");
          return;
        } else {
          let url = new URL(_this.urlValue);
          url.search = new URLSearchParams({ query: search });
          fetch(url)
            .then((response) => response.json())
            .then((json) => callback(json))
            .catch((error) => callback(error));
        }
      },
    });
  }
}
