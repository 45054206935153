import React from 'react';

const GradYearSelect = ({ handleChange }) => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 4; // Four years behind
  const endYear = currentYear + 20; // Twenty years ahead

  const generateGradYears = () => {
    const years = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  const generateOptions = () => {
    return generateGradYears().map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
  };

  return (
    <div className="half">
      <p>Graduation Year</p>
      <select
        onChange={handleChange}
        name={"graduation_year"}
        className={"grad-year field"}
        data-name={"graduation-year"}
      >
        {generateOptions()}
      </select>
    </div>
  );
};

export default GradYearSelect;