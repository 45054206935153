import React from 'react';
import Cleave from 'cleave.js/react';

const PhysicianContactNumberInput = ({ state, handleChange, checkInput }) => {
  return (
    <div className="camper-info-form">
      <label htmlFor="phone-mask-physician">
        <span className="error-span"></span>
      </label>
      <Cleave
        id="phone-mask-physician"
        className="ecn phone-us field two-thirds-field"
        type="tel"
        size={10}
        pattern="[0-9]{10}"
        name="physician_contact_number"
        placeholder="Physician Contact #"
        data-error-message="Physician Phone must be 10 characters. If you are still having issues, clear the field and try again."
        data-min-length="10"
        options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: "-" }}
        onChange={handleChange}
        onBlur={checkInput}
        value={state.physician_contact_number}
      />
    </div>
  );
};

export default PhysicianContactNumberInput;
