// src/components/WeightInput.js
import React from "react";

const WeightInput = ({ name, label, value, onChange }) => {
  const generateWeightOptions = () => {
    const options = [];
    options.push(
        <option key={"prefer-not-to-say"} value={"Prefer not to say"}>
          {"Prefer not to say"}
        </option>
      );
    options.push(
        <option key={"under-fifty"} value={"Under 50 lbs"}>
          {"Under 50 lbs"}
        </option>
      );
    for (let weight = 50; weight <= 350; weight++) {
      options.push(
        <option key={weight} value={weight}>
          {weight}
        </option>
      );
    }
    options.push(
        <option key={"over-three-fifty"} value={"Over 350 lbs"}>
          {"Over 350 lbs"}
        </option>
      );
    return options;
  };

  return (
    <div>
      <span>Weight</span>
      <label>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        {generateWeightOptions()}
      </select>
    </div>
  );
};

export default WeightInput;
