import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-view-toggle"

export default class extends Controller {
  static targets = ["password", "eye", "confirmation"];

  togglePasswordView() {
    const input = this.passwordTarget;
    const eye = this.eyeTarget;
    eye.classList.toggle('fa-eye')
    input.type = (input.type == "password") ? "text" : "password";
    if (this.hasConfirmationTarget){
      this.confirmationTarget.type = (this.confirmationTarget.type == "password") ? "text" : "password";
    }
  }

  resetPassword() {
    this.passwordTarget.type = "password";
    if (this.hasconfirmationTarget){
      this.confirmationTarget.type = "password";
    }
  }

  connect() {
    this.element.addEventListener("submit", () => {
      this.resetPassword();
    });
  }
}
