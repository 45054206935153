import React from "react";

const PositionDropdown = ({ position, sport, price_points, full_price_points, onChange }) => {
  const sportSpecificPositionSelect = (sport) => {

    const positions = {
      soccer: ["Forward", "Midfielder", "Defender", "Goalkeeper"],
      basketball: ["PG", "SG", "SF", "PF", "C"],
      lacrosse: ["Attack", "Face-Off", "Midfielder", "Defender", "Goalie"],
      hockey: ["Left Wing", "Center", "Right Wing", "Forward", "Defenseman", "Goalie"],
      football: ["QB", "RB", "WR", "TE", "OL", "DL", "LB", "CB", "SAF", "K", "P", "ATH"],
      volleyball: ["Outside Hitter", "Right Side Hitter", "Opposite Hitter", "Setter", "Middle Blocker", "Libero"],
      baseball: ["C", "P", "1B", "2B", "3B", "SS", "OF", "IF"],
      softball: ["C", "P", "1B", "2B", "3B", "SS", "OF", "IF"],
      wrestling: [
        "125 lbs",
        "133 lbs",
        "141 lbs",
        "149 lbs",
        "157 lbs",
        "165 lbs",
        "174 lbs",
        "184 lbs",
        "197 lbs",
        "285 lbs"
      ],
      tennis: ["Singles", "Doubles"],
      golf: ["Golfer"],
      "field-hockey": ["Forward", "Midfield", "Defense", "Goalkeeper"],
      swimming: ["Freestyle", "Backstroke", "Breaststroke", "Butterfly", "Medley", "Relay"],
      track: ["Sprinter", "Middle Distance", "Long Distance", "Hurdler", "Jumper", "Thrower", "Pole Vaulter", "Relay"],
      gymnastics: ["All-Around", "Vault", "Uneven Bars", "Balance Beam", "Floor Exercise"],
      "soccer-advanced": ["RB", "CB", "LB", "DCM", "CM", "ACM", "RW", "LW", "F", "GK"],
      "sports-performance": ["soccer", "football", "basketball", "baseball", "lacrosse", "field hockey", "golf", "tennis", "other"],
      rowing: ["Coxswain", "Stroke", "Bow", "Port", "Starboard"],
      "water polo": ["Goalkeeper", "Center Forward", "Driver", "Point", "Wing", "Flat"],
      fencing: ["Epee", "Foil", "Sabre"],
      rifle: ["Smallbore", "Air Rifle"],
      bowling: ["Lead", "Second", "Third", "Anchor"],
      "cross-country": ["Runner"],
      skiing: ["Alpine", "Nordic", "Freestyle", "Jumping"],
      esports: ["Top", "Jungle", "Mid", "ADC", "Support"],
      "ultimate-frisbee": ["Handler", "Cutter", "Deep"],
      cheerleading: ["Base", "Flyer", "Spotter", "Tumbler"],
      dance: ["Lead", "Backup", "Solo"],
      "martial arts": ["Striker", "Grappler", "Mixed Martial Artist"],
    };
    return positions[sport] || ["All / Any"];
  };

const setUpAvailablePositions = () => {
  const positionArray = sportSpecificPositionSelect(sport);

  const pricePointsByPosition = full_price_points?.reduce((acc, pp) => {
    if (!pp.discount) { // only consider price points that are not discounted/hidden
      if (!acc[pp.position]) {
        acc[pp.position] = [];
      }
      acc[pp.position].push(pp);
    }
    return acc;
  }, {});

  if (window.location.pathname.includes('/waitlist_invitations/')) {
    let pricePointItem = JSON.parse(price_points);
    
    if (pricePointItem.position){
      let arr = [pricePointItem.position]
      return arr
    } else {
      return sportSpecificPositionSelect(sport)
    }
  } else {
    if (!pricePointsByPosition) {
      return positionArray || sportSpecificPositionSelect(sport);
    }
    // if reg registration, check for each pp w-position if all have a registration count >= capacity
    const positionsToRemove = Object.keys(pricePointsByPosition).filter(pos => {
      return pricePointsByPosition[pos].every(pp => pp.registration_count >= pp.capacity);
    });
    return positionArray?.filter((pos) => !positionsToRemove.includes(pos)) || sportSpecificPositionSelect(sport)
  }
};

  const handlePositionChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <select
      className="position field"
      name="position"
      value={position}
      onChange={handlePositionChange}
    >
      {setUpAvailablePositions().map((pos) => (
        <option value={pos} key={pos}>
          {pos}
        </option>
      ))}
    </select>
  );
};

export default PositionDropdown;
