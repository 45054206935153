import React, { useState } from "react";
import Cleave from "cleave.js/dist/cleave-react";

const SanDiegoStateMedicalForm = ({saveValues, nextStep, buttonStyles }) => {
  const [state, setState] = useState({
    physician: "",
    physician_contact_number: "",
    physician_city: "",
    insurer: "",
    group_number: "",
    medications: "",
    conditions: "",
    allergies: "",
    diabetes: false,
    asthma: false,
    sickle_cell_positive: false,
    glasses_or_contacts: false,
  });

  const valid = () => {
    if (
      validateLength(state.physician, 5) &&
      validateLength(state.physician_contact_number, 9) &&
      validateLength(state.insurer, 4) &&
      validateLength(state.group_number, 2)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const handleChecked = (e) => {
    const { name } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.checked }));
  };

  const validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  const checkInput = (e) => {
    // updated to remove jQuery
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      if (errorSpan) {
        return $(errorSpan).html(errorMessage);
      } else {
        console.error("No .error-span element found");
      }
    } else {
      item.classList.remove("field-error");
      if (errorSpan) {
        return $(errorSpan).html("");
      } else {
        console.error("No .error-span element found");
      }
    }
  };


  const saveAndContinue = (e) => {
    e.preventDefault();
    const newData = {
      physician: state.physician,
      physician_contact_number: state.physician_contact_number,
      physician_city: state.physician_city,
      insurer: state.insurer,
      group_number: state.group_number,
      allergies: state.allergies,
      medications: state.medications,
      conditions: state.conditions,
      diabetes: state.diabetes,
      asthma: state.asthma,
      glasses_or_contacts: state.glasses_or_contacts,
      sickle_cell_positive: state.sickle_cell_positive,
    };
    saveValues(newData);
    return nextStep();
  };


  return (
    <form className={"new-step-form"}>
      <div className={"place"}>
        <h1 className={"form-heading"}>{"Step 2. Medical Information"}</h1>
        <div className={"camper-info-row"}>
          <label className={"up-size big-span"}>
            {"Physician Information: *"}
          </label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"phys full-field"}
            placeholder={"Physician Name *"}
            data-error-message={
              "Physician Name must be more than the specified number of characters."
            }
            data-min-length={"4"}
            name={"physician"}
            value={state.physician}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <span className={"error-span"}></span>
          <Cleave
            id="phone-mask"
            className={"ecn phone-us field two-thirds-field"}
            type={"tel"}
            size={10}
            pattern={"[0-9]{10}"}
            name={"physician_contact_number"}
            placeholder={"Physician Contact #"}
            data-error-message={
              "Physician Phone must be 10 characters. If you are still having issues, clear the field and try again."
            }
            data-min-length={"10"}
            options={{ numericOnly: true, blocks: [3, 3, 4], delimiter: "-" }}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"phys full-field"}
            placeholder={"Physician City *"}
            data-error-message={
              "Physician City must be more than the specified number of characters."
            }
            data-min-length={"4"}
            name={"physician_city"}
            value={state.physician_city}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
        <div className={"camper-info-row"}>
          <label className={"up-size big-span"}>
            {"Insurance Information: *"}
          </label>
          <span className={"error-span"}></span>
          <input
            type={"text"}
            className={"insurance` full-field"}
            placeholder={"Insurance Provider *"}
            required={true}
            data-error-message={
              "Insurance Provider must be filled out. No abbreviations please!"
            }
            data-min-length={"4"}
            name={"insurer"}
            value={state.insurer}
            onChange={handleChange}
            onBlur={checkInput}
          />
          <input
            type={"text"}
            className={"group-number full-field"}
            placeholder={"Insurance Group / Policy Number *"}
            required={true}
            data-error-message={
              "Insurance Group / Policy Number must be more than the specified number of characters"
            }
            data-min-length={"3"}
            name={"group_number"}
            value={state.group_number}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
        <div className={"health-container camper-info-row"}>
          <span className={"up-size"}>{"Health Overview:"}</span>
          <span className={"error-span"}></span>
          <div className={"camper-info-row"}>
            <textarea
              rows={5}
              className={"meds full-field"}
              placeholder={"Please list any Medications..."}
              name={"medications"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.medications}
            ></textarea>
            <textarea
              rows={{ 5: 5 }}
              className={"conds full-field"}
              placeholder={"Please list any Medical Conditions - etc...."}
              name={"conditions"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.conditions}
            ></textarea>
          </div>
          <div className={"camper-info-row"}>
            <textarea
              rows={{ 5: 5 }}
              className={"allergiess full-field"}
              placeholder={"Please list any Allergies...."}
              name={"allergies"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.allergies}
            ></textarea>
          </div>
          <div className="camper-info-row">
            <textarea
              rows={{ 5: 5 }}
              className={"history_of_concussion full-field"}
              placeholder={"Please list any Concussion History...."}
              name={"history_of_concussion"}
              onChange={handleChange}
              onBlur={checkInput}
              value={state.history_of_concussion}
            ></textarea>
          </div>

          <div className={"camper-info-row"}>
            <div className={"check-split half-field"}>
              <span htmlFor={"diabetes"} className={"check-span up-size"}>
                {"Diabetes"}
              </span>
              <input
                type={"checkbox"}
                className={"diabetes"}
                name={"diabetes"}
                value={state.diabetes}
                onChange={handleChecked}
              />
            </div>
            <div className={"check-split half-field"}>
              <span htmlFor={"asthma"} className={"check-span up-size"}>
                {"Asthma"}
              </span>
              <input
                type={"checkbox"}
                className={"asthma"}
                name={"asthma"}
                value={state.asthma}
                onChange={handleChecked}
              />
            </div>
          </div>
          <div className={"camper-info-row"}>
            <div className={"check-split half-field"}>
              <span
                htmlFor={"glasses_or_contacts"}
                className={"check-span up-size"}
              >
                {"Glasses or Contacts"}
              </span>
              <input
                type={"checkbox"}
                className={"glasses_or_contacts"}
                name={"glasses_or_contacts"}
                value={state.glasses_or_contacts}
                onChange={handleChecked}
              />
            </div>
            <div className={"check-split half-field"}>
              <span
                htmlFor={"sickle_cell_positive"}
                className={"check-span up-size"}
              >
                {"Sickle Cell Positive"}
              </span>
              <input
                type={"checkbox"}
                className={"sickle_cell_positive"}
                name={"sickle_cell_positive"}
                value={state.sickle_cell_positive}
                onChange={handleChecked}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        type={"submit"}
        className={"button button-primary create"}
        disabled={!valid()}
        style={buttonStyles}
        onClick={saveAndContinue}
      >
        {"Next Step"}
      </button>
    </form>
  );
}
export default SanDiegoStateMedicalForm;
