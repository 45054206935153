import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["campSelect", "pricePointSelect"];

  connect() {
    console.log('SwitchCamp controller connected');
  }

  updatePricePoints() {
    const campId = this.campSelectTarget.value;
    fetch(`/admin/camps/${campId}/price_points`)
      .then(response => response.json())
      .then(data => this.populatePricePoints(data))
      .catch(error => console.error('Error fetching price points:', error));
  }

  populatePricePoints(pricePoints) {
    const options = pricePoints.map(pp => `<option value="${pp.id}">${pp.title}</option>`).join("");
    this.pricePointSelectTarget.innerHTML = options;
  }
}
