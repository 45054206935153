// app/javascript/controllers/basic_phone_cleave_controller.js
import { Controller } from "@hotwired/stimulus"
import Cleave from "cleave.js"

export default class extends Controller {
  connect() {
    new Cleave(this.element, {
      blocks: [3, 3, 4],
      delimiter: "-",
      uppercase: true
    });
  }
}
