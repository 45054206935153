import { Controller } from "@hotwired/stimulus"
import Toastify from "toastify-js";
// Connects to data-controller="toast"
export default class extends Controller {
  static targets = ["key", "value"];
  
  connect() {
    let messageHeading = this.keyTarget;
    let messageText = this.valueTarget;
    if (messageText.textContent) {
      Toastify({
        text: messageText.textContent,
        duration: 4000,
        close: true,
        position: "right", // `left`, `center` or `right`
        className: messageHeading.textContent,
        stopOnFocus: true, // Prevents dismissing of toast on hover
      }).showToast();
    }
  }

  setBackgroundColor(){
     let messageHeading = this.keyTarget;
     switch (messageHeading.textContent) {
       case 'success':
        return '#e6efc2';
       case 'notice':
        return '#e5edf8';
       case 'error':
        return "#dc3545";
       case 'alert':
        return '#fff6bf';
       default:
        return "#3ecf8e";
    }
  }
}
