import React from 'react';

const EmergencyContactInput = ({
  label,
  name,
  value,
  required,
  type = "text", // set default type to text
  autoComplete = "off", // set default autoComplete to off
  onChange, // Use `onChange` directly as your prop name
  checkInput, 
  errorMessage
}) => {
  const hasError = errorMessage && errorMessage.length > 0;

  return (
    <div className={"camper-info-row"}>
      <label htmlFor={name} className={"big-span"}>{label}</label>
      {hasError && (
        <span className={"error-span"} role="alert" aria-live="assertive">
          {errorMessage}
        </span>
      )}
      <input
        type={type}
        id={name}
        name={name}
        className={"full-field"}
        aria-describedby={hasError ? "errorEmergencyContact" : null}
        aria-invalid={hasError}
        placeholder={"Emergency Contact Full Name"}
        minLength={4}
        required={required}
        value={value} // Controlled component
        onChange={onChange} // Use `onChange` directly here
        onBlur={checkInput}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default EmergencyContactInput;
