import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="per-page-select"
export default class extends Controller {
  static targets = ["option"];
  connect() {
    let itemSelect = document.querySelector('#item-select-toggle')
    itemSelect.addEventListener('change', function(event){
      let options = itemSelect.options
      let selectedOption = options[itemSelect.options.selectedIndex]
      let url = window.location.origin + selectedOption.dataset.url
      window.location.replace(url);
    })
  }
}
