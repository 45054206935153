import React, { useState, useEffect } from "react";

const TextField = ({
  label,
  name,
  value, // This prop will be used to control the input value
  required,
  type,
  autoComplete,
  onChange,
}) => {
  const [error, setError] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange({ target: { name: name, value: newValue } });
    }
  };

  // useEffect(() => {
  //   // Run validation on value change
  //   setError(validateInput(value)); // Make sure validateInput is defined
  // }, [value]);

  return (
    <div className="text-field-container">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        id={name}
        name={name}
        type={type}
        required={required}
        autoComplete={autoComplete}
        placeholder={label} // Use label as a placeholder
        value={value}
        onChange={handleChange}
        aria-label={label}
      />
      {error && <span className="error-span">{error}</span>}
    </div>
  );
};

export default TextField;
