// src/components/Checkbox.js
import React from 'react';

const Checkbox = ({ label, name, value, onChange }) => {
  return (
    <div className={"check-split third-field"}>
      <h4>{label.toUpperCase()}</h4>
      <label htmlFor={name} className={"check-span up-size"}>
        {label}
      </label>
      <input
        type="checkbox"
        className={name}
        name={name}
        checked={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Checkbox;
