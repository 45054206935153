import React, { useState, useEffect } from "react";
import PositionDropdown from "./position_dropdown.js";
import HeightField from "./height_field.js";
import WeightField from "./weight_field.js";
import WeightInput from "./form_fields/weight_input.js";
import YearsInSportField from "./years_in_sport_field.js";
import YearsOfTrainingField from "./years_of_training_field.js";

const SportsPerformanceForm = ({ collect_tee_shirt_size, saveValues, nextStep, ...props }) => {

  const setDefaultPosition = (sport) => {
    const defaultPositions = {
      soccer: "Forward",
      basketball: "PG",
      lacrosse: "Attack",
      hockey: "Forward",
      volleyball: "Outside Hitter",
      baseball: "C",
      softball: "C",
      wrestling: "Wrestler",
      "soccer-advanced": "CM",
      "sports-performance": "other",
    };

    return defaultPositions[sport] || "All / Any";
  };

  const defaultPosition = setDefaultPosition(props.sport);

  const [state, setState] = useState(() => ({
    height: "",
    weight: "",
    position: defaultPosition,
    graduation_year: "2022",
    shirt_size: "N/A",
    injury_history: "",
    training_experience: "",
    goals: "",
    sports: "",
    years_in_sport: "",
    years_of_training: "",
    user_notes: "",
    custom_data: {},
  }));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const heightChanged = (newHeight) => {
    setState((prevState) => ({ ...prevState, height: newHeight }));
  };

  const weightChanged = (newWeight) => {
    setState((prevState) => ({ ...prevState, weight: newWeight }));
  };

  const yearsInSportChanged = (newYear) => {
    setState((prevState) => ({ ...prevState, years_in_sport: newYear }));
  };

  const trainingYearsChanged = (newYear) => {
    setState((prevState) => ({ ...prevState, years_of_training: newYear }));
  };

  const valid = () => {
    return true
  };

  const setUpGradYearOptions = () => {
     const gradYears = [
       "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "N/A",
     ];

     return gradYears.map((gradYear) => (
       <option value={gradYear} key={gradYear}>
         {gradYear}
       </option>
     ));
   };

   const addGoalsNotesFields = () => {
     if (props.custom_registration_type == "sports-performance") {
       return (
         <React.Fragment>
           <div className="half">
             <label>Goals:</label>
             <span className="error-span"></span>
             <textarea
               rows={{ 5: 5 }}
               name="goals"
               value={state.goals}
               onChange={handleChange}
               placeholder="What are your goals?"
             ></textarea>
           </div>
           <div className="half">
             <label>Notes:</label>
             <span className="error-span"></span>
             <textarea
               rows={{ 5: 5 }}
               name="user_notes"
               value={state.user_notes}
               onChange={handleChange}
               placeholder="Anything else you'd like for us to know"
             ></textarea>
           </div>
         </React.Fragment>
       );
     }
   };

   const setUpShirtSizeOptions = () => {
     const shirtSizes = [
       "N/A", "Y-Small", "Y-Medium", "Y-Large", "Small", "Medium", "Large", "X-Large",
     ];

     return shirtSizes.map((shirtSize) => (
       <option value={shirtSize} key={shirtSize}>
         {shirtSize}
       </option>
     ));
   };

   const addShirtField = () => {
     if (collect_tee_shirt_size) {
       return (
         <div className="camper-info-row">
           <div className="half">
             <p>Shirt Size (if applicable): *</p>
             <span className="error-span"></span>
             <select
               onChange={handleChange}
               name={"shirt_size"}
               className={"shirt-size field"}
               data-name={"shirt_size"}
             >
               {setUpShirtSizeOptions()}
             </select>
           </div>
         </div>
       );
     }
   };

  const checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

  const positionChange = (positionValue) => {
    const fieldName = "position";
    return setState({ [fieldName]: positionValue });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    return setState({ [name]: e.target.value });
  };

  const saveAndContinue = (e) => {
    e.preventDefault();
    const data = {
      height: state.height,
      weight: state.weight + "lbs",
      position: state.position,
      club_team: state.club_team,
      gpa: state.gpa,
      sat: state.sat,
      act_score: state.act_score,
      graduation_year: state.graduation_year,
      roommate_preference: state.roommate_preference,
      peers_attending: state.peers_attending,
      shirt_size: state.shirt_size,
      goals: state.goals,
      injury_history: state.injury_history,
      training_experience: state.training_experience,
      sports: state.sports,
      years_in_sport: state.years_in_sport,
      years_of_training: state.years_of_training,
    };
    saveValues(data);
    return nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <h1 className={"form-heading"}>
        {"Step 3. Sports Performance Information"}
      </h1>
      <div className={"camper-info-row"}>
        <div className={"half"}>
          <span className={"error-span"}></span>
          <label>{"Sports: *"}</label>
          <input
            type={"text"}
            className={"club-team full-field"}
            placeholder={
              "List sports seperated by a , (ex: soccer, hockey, basketball, baseball)"
            }
            name={"sports"}
            data-error-message={
              "Sports must be at least one character - N/A is a valid response."
            }
            data-min-length={"1"}
            value={state.sports}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      </div>
      <div className={"camper-info-row"}>
         <React.Fragment>
          <div className={"camper-info-row"}>
            <p>Years in Sport</p>
            <YearsInSportField
              name={"years_in_sport"}
              value={state.years_in_sport}
              onChange={yearsInSportChanged}
            />
            <p>Years of Training</p>
            <YearsOfTrainingField
              name={"years_of_training"}
              value={state.years_of_training}
              onChange={trainingYearsChanged}
            />
          </div>
          <div className={"camper-info-row"}>
            <div className="half">
              <label>Injury History:</label>
              <span className="error-span"></span>
              <textarea
                rows={{ 5: 5 }}
                name="injury_history"
                value={state.injury_history}
                onChange={handleChange}
                placeholder="Your injury history"
              ></textarea>
            </div>
            <div className="half">
              <label>Training Experience:</label>
              <span className="error-span"></span>
              <textarea
                rows={{ 5: 5 }}
                name="training_experience"
                value={state.training_experience}
                onChange={handleChange}
                placeholder="Your previous training experience"
              ></textarea>
            </div>
          </div>
        </React.Fragment>
      </div>
      <div className={"camper-info-row"}>{addGoalsNotesFields()}</div>
      <div className={"camper-info-row"}>{addShirtField()}</div>
      <div className={"camper-info-row"}>
        <button
          type={"submit"}
          className={"button button-primary create"}
          disabled={!valid()}
          style={props.buttonStyles}
          onClick={saveAndContinue}
        >
          {"Next Step"}
        </button>
      </div>
    </form>
  );
}
export default SportsPerformanceForm
