import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["select", "duration"];

  connect() {
    this.checkCategory();
  }

  checkCategory() {
    if (this.selectTarget.value != "bookable_service") {
      this.durationTarget.style.display = "none";
    } else {
      this.durationTarget.style.display = "block";
    }
  }

  categoryChanged() {
    this.checkCategory();
  }
}

