import { Controller } from "@hotwired/stimulus";
import * as Toastify from "toastify-js";

export default class extends Controller {
  static targets = ["source"];
  static classes = ["supported"];
  connect() {
    navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
      if (result.state === "granted") {
        this.element.classList.add(this.supportedClass);
      }
    });
  }
  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.value);

    Toastify({
      text: "Link copied to clipboard!",
      duration: 3000,
      close: false,
      position: "right", // `left`, `center` or `right`
      backgroundColor: "#3ecf8e",
      className: "",
      stopOnFocus: true, // Prevents dismissing of toast on hover
    }).showToast();
  }
}
