import React, { useState, useEffect, useRef } from "react";
import PositionDropdown from "./position_dropdown.js";

const BballForm = (props) => {

  const setDefaultPosition = (sport) => {
    const defaultPositions = {
      soccer: "Forward",
      basketball: "PG",
      lacrosse: "Attack",
      hockey: "Forward",
      volleyball: "Outside Hitter",
      baseball: "C",
      softball: "C",
      wrestling: "Wrestler",
      "soccer-advanced": "CM",
      "sports-performance": "other",
    };

    return defaultPositions[sport] || "All / Any";
  };

  const [state, setState] = useState({
    height: "",
    weight: "",
    position: setDefaultPosition(props.sport),
    gpa: "",
    sat: "",
    act_score: "",
    club_team: "",
    roommate_preference: "",
    peers_attending: "",
    graduation_year: "2019",
    shirt_size: "N/A",
    custom_data: {},
  });

  const clubTeamRef = useRef();
  const heightRef = useRef();
  const weightRef = useRef();
  const gpaRef = useRef();
  const satRef = useRef();
  const gradYearRef = useRef();
  const shirtSizeRef = useRef();
  const roommatePreferenceRef = useRef();
  const peersAttendingRef = useRef();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const valid = () => {
    return state.height && state.weight;
  };

  const validateEmail = (email) => {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  };

  const validateLength = (field, requiredLength) => {
    if (field.length > requiredLength) {
      return true;
    }
  };

  const validateStreetAddress = (address) => {
    return console.log("validate");
  };

  const capitalizeString = (string) => {
    const capped = string.charAt(0).toUpperCase() + string.slice(1);
    return capped;
  };

  const addRoommatePreferenceField = () => {
    if (props.event_type == "traditional") {
      return React.DOM.div(
        { className: "half" },
        React.DOM.label,
        "Roommate Preferences:",
        React.DOM.span({
          className: "error-span",
        }),
        React.DOM.textarea({
          rows: { 5: 5 },
          placeholder: "Please list any roommate preferences...",
          name: "roommate_preference",
          ref: "roommate_preference",
          value: state.roommate_preference,
          onChange: handleChange,
        })
      );
    }
  };

  const addIdCampFields = () => {
    if (props.event_type != "day-camp") {
      return (
        React.DOM.div(
          { className: "camper-info-row" },
          React.DOM.div(
            { className: "half" },
            React.DOM.label({ className: "" }, "GPA:"),
            React.DOM.span({
              className: "error-span",
            }),
            React.DOM.input({
              type: "text",
              className: "gpa",
              placeholder: "Enter GPA",
              name: "gpa",
              ref: "gpa",
              required: "true",
              value: state.gpa,
              "data-error-message":
                "GPA field must not be blank - N/A is a valid response.",
              "data-min-length": "2",
              onChange: handleChange,
              onBlur: checkInput,
            })
          ),
          React.DOM.div(
            { className: "half" },
            React.DOM.label({ className: "" }, "SAT:"),
            React.DOM.span({
              className: "error-span",
            }),
            React.DOM.input({
              type: "text",
              className: "sat",
              placeholder: "Enter SAT",
              name: "sat",
              ref: "sat",
              required: "true",
              value: state.sat,
              "data-error-message":
                "SAT field must not be blank - N/A is a valid response.",
              "data-min-length": "2",
              onChange: handleChange,
              onBlur: checkInput,
            })
          )
        ),
        React.DOM.div(
          { className: "half" },
          React.DOM.label,
          "Graduation Year",
          React.DOM.select(
            {
              onChange: handleChange,
              name: "graduation_year",
              className: "grad-year field",
              ref: "grad-year",
              "data-name": "graduation-year",
            },
            [
              "2019",
              "2020",
              "2021",
              "2022",
              "2023",
              "2024",
              "2025",
              "2026",
              "2027",
              "2028",
              "2029",
              "2030",
              "N/A",
            ].map((year) => React.DOM.option({ value: year, key: year }, year))
          )
        )
      );
    }
  };

  const addShirtField = () => {
    if (props.collect_tee_shirt_size) {
      return React.DOM.div(
        { className: "camper-info-row" },
        React.DOM.div(
          { className: "half" },
          React.DOM.span({
            className: "error-span",
          }),
          React.DOM.label,
          "Shirt Size (if applicable): * ",
          React.DOM.select(
            {
              className: "shirt-size field",
              name: "shirt_size",
              ref: "shirt_size",
              onChange: handleChange,
            },
            Array.from(shirtSizes()).map((shirt_size) =>
              React.DOM.option(
                { value: shirt_size, key: shirt_size },
                shirt_size
              )
            )
          )
        )
      );
    }
  };

  const checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
      if (fieldValue <= minLength) {
        item.classList.add("field-error");
        const errorMessage = item.dataset.errorMessage.replace(
          "the specified number of",
          item.dataset.minLength.toString()
        );
        return $(errorSpan).html(errorMessage);
      } else {
        item.classList.remove("field-error");
        return $(errorSpan).html("");
      }
    };


  const shirtSizes = () => {
  return [
    "N/A",
    "Y-Small",
    "Y-Medium",
    "Y-Large",
    "Small",
    "Medium",
    "Large",
    "X-Large",
  ];
};


  const positionChange = (positionValue) => {
    const fieldName = "position";
    return setState({ [fieldName]: positionValue });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    return setState({ [name]: e.target.value });
  };

    const saveAndContinue = (e) => {
    e.preventDefault();
    let strippedHeight = state.height.replace(/['"]+/g, "");
    const data = {
      height: strippedHeight,
      weight: state.weight,
      position: state.position,
      club_team: state.club_team,
      gpa: state.gpa,
      sat: state.sat,
      graduation_year: state.graduation_year,
      roommate_preference: state.roommate_preference,
      peers_attending: state.peers_attending,
      shirt_size: state.shirt_size,
    };
    props.saveValues(data);
    return props.nextStep();
  };

  return (
    <form className={"new-step-form"}>
      <h1 className={"form-heading"}>
        {"Step 3. " +
          capitalizeString(props.sport) +
          " Information"}
      </h1>
      <div className="camper-info-row">
        <div className="half">
          <span className="error-span"></span>
          <label className="h">Position: *</label>
          <PositionDropdown
            onChange={positionChange}
            value={state.position}
            sport={props.sport}
            price_points={props.price_point}
          />
        </div>
        <div className="half">
          <span className="error-span"></span>
          <label>Club Team: *</label>
          <input
            type="text"
            className="club-team full-field"
            placeholder="Club Team"
            name="club_team"
            ref={clubTeamRef}
            required
            data-error-message="Club Team must be at least one character - N/A is a valid response."
            data-min-length="1"
            value={state.club_team}
            onChange={handleChange}
            onBlur={checkInput}
          />
        </div>
      </div>
      <div className={"camper-info-row"}>
        <span className={"error-span"}></span>
        <label>{"Height: *"}</label>
        <input
          type={"text"}
          className={"height full-field"}
          placeholder={"Height"}
          name={"height"}
          ref={"height"}
          required={"true"}
          data-error-message={
            "Height must be at least one character. No quotes please!"
          }
          data-min-length={"1"}
          title={"No quotes please"}
          value={state.height}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>
        <span className={"error-span"}></span>
        <label>{"Weight: *"}</label>
        <input
          type={"text"}
          className={"weight full-field"}
          placeholder={"Weight"}
          pattern={"[^'\x22]+"}
          name={"weight"}
          ref={"weight"}
          required={"true"}
          data-error-message={
            "Weight must be at least one character. No quotes please!"
          }
          data-min-length={"1"}
          title={"No quotes please"}
          value={state.weight}
          onChange={handleChange}
          onBlur={checkInput}
        />
      </div>
      <div className={"camper-info-row"}>{addIdCampFields()}</div>
      <div className={"camper-info-row"}>
        <div className={"half"}>
          <span className={"error-span"}></span>
          <textarea
            rows={{ 5: 5 }}
            className={"allergiess full-field"}
            placeholder={"Please list any peers attending...."}
            name={"peers_attending"}
            ref={"peers_attending"}
            onChange={handleChange}
            onBlur={checkInput}
            value={state.peers_attending}
          ></textarea>
        </div>
        {addRoommatePreferenceField()}
      </div>
      <div className={"camper-info-row"}>{addShirtField()}</div>
      <div className={"camper-info-row"}>
        <button
          type={"submit"}
          className={"button button-primary create"}
          disabled={!valid()}
          style={props.buttonStyles}
          onClick={saveAndContinue}
        >
          {"Next Step"}
        </button>
      </div>
    </form>
  );
}
export default BballForm;
