import React from "react";

const ActScoreField = ({ name, label, value, onChange }) => {
  const generateACTScoreOptions = () => {
    const options = [];
    for (let score = 1; score <= 36; score++) {
      options.push(
        <option key={score} value={score}>
          {score}
        </option>
      );
    }
    return options;
  };

  return (
    <div>
      <span>ACT Score</span>
      <label htmlFor={name}>{label}</label>
      <select name={name} value={value} onChange={onChange}>
        {generateACTScoreOptions()}
      </select>
    </div>
  );
};

export default ActScoreField;
